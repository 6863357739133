import React, { useState, useEffect } from "react";
import Clock from "../../../utilities/Clock";
import Greeting from "../../../utilities/Greeting";
import FluidWrap from "./FluidWrap";
import TankContainer from "./TankContainer";
import Loading from "../../../utilities/Loading";
import axios from "../../../../utils/axios";
import { Box } from "@mui/material";

const UserAdminPanel = () => {
  const [levelDevices, setLevelDevices] = useState([]);
  const [listDevices, setListDevices] = useState([]);

  const getList = async () => {
    try {
      const response = await axios.post("view/devices");

      const flowDevices = response?.data?.filter(
        (item) => item.device_type === "flow"
      );
      setListDevices(flowDevices);

      setLevelDevices(
        response?.data?.filter((item) => item.device_type === "level")
      );
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getList();
  }, []);

  const handlePinClick = (index, pinned) => {
    const newItems = [...listDevices];
    const item = newItems.splice(index, 1)[0];
    item.pinned = pinned;
    item.isPinned = pinned;
    if (pinned) {
      newItems.unshift(item);
    } else {
      let i = 0;
      while (i < newItems.length && newItems[i].pinned) {
        i++;
      }
      newItems.splice(i, 0, item);
    }
    setListDevices(newItems);
    localStorage.setItem(item.id.toString(), pinned.toString());
  };

  return (
    <div className="home-screen">
      <Loading />
      <div className="greeting-text">
        <Greeting />
        <Clock />
      </div>

      <h6 className="title">Active Devices</h6>
      <div>
        <div>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(auto-fill, 20em)",
              gap: 5,
            }}
          >
            {Array.isArray(listDevices) &&
              listDevices?.map((item, index) => (
                <FluidWrap
                  key={index}
                  index={index}
                  pinned={item?.pinned}
                  onPinClick={() => handlePinClick(index, !item?.pinned)}
                  isPinned={item?.isPinned}
                  name={item?.alias_name}
                  deviceId={item?.id}
                  date={new Date(item?.subscription_end).toLocaleDateString(
                    "en-US",
                    {
                      year: "numeric",
                      month: "short",
                      day: "numeric",
                    }
                  )}
                />
              ))}
            {levelDevices?.length !== 0 ? (
              levelDevices.map((item, index) => (
                <TankContainer
                  key={index}
                  index={index}
                  name={item.alias_name}
                  date={new Date(item.subscription_end).toLocaleDateString(
                    "en-US",
                    {
                      year: "numeric",
                      month: "short",
                      day: "numeric",
                    }
                  )}
                  deviceId={item.id}
                />
              ))
            ) : (
              <h3 className="text-danger text-center pt-5" />
            )}
          </Box>
        </div>
      </div>
    </div>
  );
};

export default UserAdminPanel;
