import React from "react";
import SidebarIcon from "./SidebarIcon";
import { useLocation } from "react-router-dom";

const Sidebar = () => {
  let location = useLocation();

  return (
    <div className="sidebar-wrap user">
      <SidebarIcon
        link="/dealer-panel"
        active={location.pathname === "/dealer-panel" && "active"}
        icon={
          <svg
            width="46"
            height="52"
            viewBox="0 0 46 52"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="home"
          >
            <path
              d="M9.86338 9.9798L22.7156 0.394043L35.2138 9.72467L44.9868 17.153V50.7199H26.2787V33.1623H22.7018L19.0587 33.1271V50.6951L0.284424 50.7199V17.1584L9.86338 9.9798Z"
              fill="#09C4FF"
            />
            <path
              d="M4.38912 46.5896H14.9219V29.0351H30.3699V46.5896H40.9026V19.2045L22.6459 5.51197L4.38912 19.2045V46.5896ZM0.176025 50.8027V17.098L22.6459 0.245605L45.1157 17.098V50.8027H26.1568V33.2482H19.1349V50.8027H0.176025Z"
              fill="#09C4FF"
            />
          </svg>
        }
      />

      <SidebarIcon
        link="/dealer-customer"
        active={
          location.pathname === "/dealer-customer" ||
          (location.pathname === "/dealer-customer/customer-detail" && "active")
        }
        icon={
          <svg
            width="71"
            height="71"
            viewBox="0 0 71 71"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="9.63086"
              y="10.4355"
              width="52.5"
              height="53.9583"
              rx="6"
              fill="white"
              fillOpacity="0.5"
            />
            <path
              d="M35.8444 52.7272C32.6604 52.7272 29.61 53.2498 26.6934 54.2949C23.7767 55.3401 21.0059 56.9807 18.3809 59.2168V60.0189H53.3809V59.6543C50.9503 57.4668 48.2159 55.7654 45.1777 54.5501C42.1395 53.3349 39.0284 52.7272 35.8444 52.7272ZM14.0059 57.1022C16.8253 54.3314 20.1187 52.1803 23.8861 50.6491C27.6534 49.1178 31.6517 48.3522 35.8809 48.3522C40.11 48.3522 44.0961 49.1178 47.8392 50.6491C51.5823 52.1803 54.8878 54.3314 57.7559 57.1022V14.8105H14.0059V57.1022ZM35.8809 41.4981C33.11 41.4981 30.7645 40.538 28.8444 38.6178C26.9243 36.6977 25.9642 34.3522 25.9642 31.5814C25.9642 28.8106 26.9243 26.4651 28.8444 24.5449C30.7645 22.6248 33.11 21.6647 35.8809 21.6647C38.6517 21.6647 40.9972 22.6248 42.9173 24.5449C44.8375 26.4651 45.7975 28.8106 45.7975 31.5814C45.7975 34.3522 44.8375 36.6977 42.9173 38.6178C40.9972 40.538 38.6517 41.4981 35.8809 41.4981ZM35.898 37.1231C37.4421 37.1231 38.7489 36.5826 39.8184 35.5018C40.8878 34.4208 41.4225 33.1083 41.4225 31.5643C41.4225 30.0201 40.8821 28.7133 39.8012 27.6439C38.7203 26.5744 37.4078 26.0397 35.8637 26.0397C34.3196 26.0397 33.0128 26.5801 31.9434 27.661C30.8739 28.7419 30.3392 30.0544 30.3392 31.5985C30.3392 33.1427 30.8796 34.4494 31.9605 35.5189C33.0414 36.5883 34.3539 37.1231 35.898 37.1231ZM14.0059 64.3939C12.8392 64.3939 11.8184 63.9564 10.9434 63.0814C10.0684 62.2064 9.63086 61.1856 9.63086 60.0189V14.8105C9.63086 13.6439 10.0684 12.623 10.9434 11.748C11.8184 10.873 12.8392 10.4355 14.0059 10.4355H18.7454L23.485 10.4355L48.2767 10.4355H53.0163H57.7559C58.9225 10.4355 59.9434 10.873 60.8184 11.748C61.6934 12.623 62.1309 13.6439 62.1309 14.8105V60.0189C62.1309 61.1856 61.6934 62.2064 60.8184 63.0814C59.9434 63.9564 58.9225 64.3939 57.7559 64.3939H14.0059ZM35.8809 60.0189H53.3809H18.3809H35.8809Z"
              fill="#09C4FF"
            />
          </svg>
        }
      />

      <SidebarIcon
        link="/dealer-Devices"
        active={location.pathname === "/dealer-Devices" && "active"}
        icon={
          <svg
            width="71"
            height="71"
            viewBox="0 0 71 71"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M27.3306 42.2137V31.9598H24.4083V27.667H46.2143V31.9484H43.3438V42.1627H58.662V58.9618H11.9954V42.2137H27.3306Z"
              fill="white"
              fillOpacity="0"
            />
            <path
              d="M33.1412 25.4201V13.7534H21.4745V9.37842H49.1829V13.7534H37.5162V25.4201H33.1412ZM11.9954 58.9618V42.1909H16.3704H27.3079V31.9826H24.3912V27.6076H46.2662V31.9826H43.3495V42.1909H54.287H58.662V58.9618H54.287H16.3704H11.9954ZM16.3704 54.5868H54.287V46.5659H38.9745V31.9826H31.6829V46.5659H16.3704V54.5868Z"
              fill="#09C4FF"
            />
          </svg>
        }
      />

      <SidebarIcon
        link="/dealer-report"
        active={location.pathname === "/dealer-report" && "active"}
        icon={
          <svg
            width="61"
            height="61"
            viewBox="0 0 61 61"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.3287 11.6284C10.3287 8.31471 13.015 5.62842 16.3287 5.62842H34.0099C35.6876 5.62842 37.2887 6.33083 38.4248 7.56529L43.2016 12.7556L48.3886 17.5223C49.625 18.6585 50.3287 20.2609 50.3287 21.9401V49.6284C50.3287 52.9421 47.6424 55.6284 44.3287 55.6284H16.3287C13.015 55.6284 10.3287 52.9421 10.3287 49.6284V11.6284Z"
              fill="white"
              fillOpacity="0"
            />
            <path
              d="M18.668 47.2888V44.8271L20.6114 42.8836V47.2888H18.668ZM24.0125 47.2888V39.6445L25.9559 37.7011V47.2888H24.0125ZM29.357 47.2888V37.7011L31.3005 39.6769V47.2888H29.357ZM34.7015 47.2888V39.6769L36.645 37.7335V47.2888H34.7015ZM40.046 47.2888V34.462L41.9895 32.5185V47.2888H40.046ZM18.668 39.6445V36.8913L27.7375 27.8866L32.92 33.0692L41.9895 23.9673V26.7205L32.92 35.8224L27.7375 30.6398L18.668 39.6445Z"
              fill="#09C4FF"
            />
            <path
              d="M14.0787 55.6284C13.0787 55.6284 12.2037 55.2534 11.4537 54.5034C10.7037 53.7534 10.3287 52.8784 10.3287 51.8784V9.37842C10.3287 8.37842 10.7037 7.50342 11.4537 6.75342C12.2037 6.00342 13.0787 5.62842 14.0787 5.62842H36.6412L50.3287 19.3159V51.8784C50.3287 52.8784 49.9537 53.7534 49.2037 54.5034C48.4537 55.2534 47.5787 55.6284 46.5787 55.6284H14.0787ZM34.7662 21.0034V9.37842H14.0787V51.8784H46.5787V21.0034H34.7662Z"
              fill="#09C4FF"
            />
          </svg>
        }
      />
    </div>
  );
};

export default Sidebar;
