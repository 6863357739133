import React, { useEffect, useState } from 'react'
import logo from '../../assets/img/favicon.svg'

const Loading = () => {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // simulate a delay in loading data
        const timeoutId = setTimeout(() => {
            setIsLoading(false);
        }, 2700);

        // cleanup function to clear the timeout
        return () => clearTimeout(timeoutId);
    }, []);

    return (
        <div className={isLoading ? 'loading' : 'loading-close'}>
            <div className="loading-left"></div>
            <div className="loading-right"></div>
            <div className="loading-img-wrap">
                <img src={logo} alt="" className="loader-img" />
            </div>
        </div>
    )
}

export default Loading