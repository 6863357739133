import { useMemo } from "react";
import { Page, View, Text, Document, StyleSheet } from "@react-pdf/renderer";
import { displayTimeDifferenceBetweenDates } from "../../../../../utils/displayTimeDifferenceBetweenDates";

const useStyles = () =>
  useMemo(
    () =>
      StyleSheet.create({
        col4: { width: "25%" },
        col8: { width: "75%" },
        col6: { width: "50%" },
        mb4: { marginBottom: 4 },
        mb8: { marginBottom: 8 },
        mb40: { marginBottom: 40 },
        h3: { fontSize: 16, fontWeight: 700 },
        h4: { fontSize: 13, fontWeight: 700 },
        body1: { fontSize: 10 },
        body2: { fontSize: 9 },
        subtitle1: { fontSize: 10, fontWeight: 700 },
        subtitle2: { fontSize: 9, fontWeight: 700 },
        alignRight: { textAlign: "right" },
        page: {
          fontSize: 9,
          lineHeight: 1.6,
          backgroundColor: "#FFFFFF",
          textTransform: "capitalize",
          padding: "40px 24px 120px 24px",
        },
        footer: {
          left: 0,
          right: 0,
          bottom: 0,
          padding: 24,
          margin: "auto",
          borderTopWidth: 1,
          borderStyle: "solid",
          position: "absolute",
          borderColor: "#DFE3E8",
        },
        gridContainer: {
          flexDirection: "row",
          justifyContent: "space-between",
        },
        table: {
          display: "flex",
          width: "auto",
        },
        tableRow: {
          padding: "8px 0",
          flexDirection: "row",
          borderBottomWidth: 1,
          borderStyle: "solid",
          borderColor: "#DFE3E8",
        },
        noBorder: {
          paddingTop: 8,
          paddingBottom: 0,
          borderBottomWidth: 0,
        },
        tableCell_1: {
          width: "5%",
        },
        tableCell_2: {
          width: "50%",
          paddingRight: 16,
        },
        tableCell_3: {
          width: "15%",
        },
      }),
    []
  );

const ExportTableView = ({
  currentItems,
  type,
  reportType,
  interval,
  tableHead,
}) => {
  const styles = useStyles();
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <Text style={[styles.subtitle1, styles.mb8]}>Report</Text>

        <View style={styles.table} key="123">
          <View key="456">
            <View style={styles.tableRow} key="789">
              {tableHead?.map((data, index) => {
                let currentStyle;
                if (index === 0) {
                  currentStyle = styles.tableCell_1;
                } else if (index === 1) {
                  currentStyle = styles.tableCell_2;
                } else {
                  currentStyle = styles.tableCell_3;
                }
                return (
                  <View style={currentStyle} key={`abc-${index}`}>
                    <Text style={styles.subtitle2}>{data}</Text>
                  </View>
                );
              })}
            </View>
          </View>

          <View key="555">
            {currentItems.map((item, index) => (
              <View style={styles.tableRow} key={`mgc-${index}`}>
                {reportType === "Flow Report" ? (
                  interval === "" ? (
                    <>
                      <View style={styles.tableCell_1} key="oge">
                        <Text>{index + 1}</Text>
                      </View>

                      <View style={styles.tableCell_2} key="qwe">
                        <Text style={styles.subtitle2}>{item.date_time}</Text>
                      </View>

                      <View style={styles.tableCell_3} key="rty">
                        <Text>{item.flowrate}</Text>
                      </View>

                      <View style={styles.tableCell_3} key="i">
                        <Text>{item.totalizer}</Text>
                      </View>

                      <View style={styles.tableCell_3} key="o">
                        <Text>{item.change_totalizer}</Text>
                      </View>
                    </>
                  ) : (
                    <>
                      <View style={styles.tableCell_1} key="p">
                        <Text>{index + 1}</Text>
                      </View>

                      <View style={styles.tableCell_2} key="as">
                        <Text style={styles.subtitle2}>
                          <Text style={styles.subtitle2}>
                            {item?.date_column}
                          </Text>
                        </Text>
                      </View>

                      <View style={styles.tableCell_3} key="df">
                        <Text>{item.avg_flowrate}</Text>
                      </View>

                      <View style={styles.tableCell_3} key="gh">
                        <Text>{item.max_totalizer}</Text>
                      </View>

                      <View style={styles.tableCell_3} key="jk">
                        <Text>{item.change_max_totalizer}</Text>
                      </View>
                    </>
                  )
                ) : reportType === "Battery Report" ? (
                  <>
                    <View style={styles.tableCell_1} key="oge2">
                      <Text>{index + 1}</Text>
                    </View>

                    <View style={styles.tableCell_2} key="qwe3">
                      <Text style={styles.subtitle2}>{`${new Date(item.start)
                        .toLocaleDateString("en-GB")
                        .slice(0, 10)} ${new Date(
                        item.date_time
                      ).toLocaleTimeString("en-US", {
                        hour12: false,
                      })}`}</Text>
                    </View>
                    <View style={styles.tableCell_2} key="qwe5">
                      <Text style={styles.subtitle2}>{`${new Date(item.end)
                        .toLocaleDateString("en-GB")
                        .slice(0, 10)} ${new Date(item.end).toLocaleTimeString(
                        "en-US",
                        {
                          hour12: false,
                        }
                      )}`}</Text>
                    </View>

                    <View style={styles.tableCell_3} key="rty7">
                      <Text>ON</Text>
                    </View>
                  </>
                ) : (
                  <>
                    <View style={styles.tableCell_1} key="lk">
                      <Text>{index + 1}</Text>
                    </View>

                    <View style={styles.tableCell_2} key="mn">
                      <Text style={styles.subtitle2}>
                        {" "}
                        <Text style={styles.subtitle2}>
                          {`${new Date(item.start_time)
                            .toLocaleDateString("en-GB")
                            .slice(0, 10)} ${new Date(
                            item.start_time
                          ).toLocaleTimeString("en-US", {
                            hour12: false,
                          })}`}
                        </Text>
                        <Text className="text-primary mx-4">To</Text>
                        <Text style={styles.subtitle2}>
                          {`${new Date(item.end_time)
                            .toLocaleDateString("en-GB")
                            .slice(0, 10)} ${new Date(
                            item.end_time
                          ).toLocaleTimeString("en-US", {
                            hour12: false,
                          })}`}
                        </Text>
                      </Text>
                    </View>

                    <View style={styles.tableCell_3} key="vb">
                      <Text>
                        {" "}
                        {displayTimeDifferenceBetweenDates({
                          endDate: item?.end_time,
                          startDate: item?.start_time,
                        })}
                      </Text>
                    </View>

                    <View style={styles.tableCell_3} key="cf">
                      <Text>
                        {" "}
                        {displayTimeDifferenceBetweenDates({
                          endDate: item?.end_time,
                          startDate: item?.start_time,
                        })}
                      </Text>
                    </View>
                  </>
                )}
              </View>
            ))}
          </View>
        </View>
      </Page>
    </Document>
  );
};
export default ExportTableView;
