export const displayTimeDifferenceBetweenDates = ({ startDate, endDate }) => {
  // Start and end dates in ISO format
  const newStartDate = new Date(startDate);
  const newEndDate = new Date(endDate);
  // Calculate the time difference in milliseconds
  const timeDifference = newEndDate - newStartDate;
  // Convert the time difference to hours, minutes, and seconds
  const seconds = Math.floor((timeDifference / 1000) % 60);
  const minutes = Math.floor((timeDifference / (1000 * 60)) % 60);
  const hours = Math.floor(timeDifference / (1000 * 60 * 60));

  // Format the time difference
  const formattedTimeDifference = `${String(hours).padStart(2, "0")}:${String(
    minutes
  ).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;

  return formattedTimeDifference;
};
