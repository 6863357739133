import React, { useState, useEffect } from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import dayjs from "dayjs";
import RadioCard from "./RadioCard";
import FilterDropdown from "../../../utilities/FilterDropdown";
import * as XLSX from "xlsx";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import excel from "../../../../assets/icons/Excel.svg";
import pdf from "../../../../assets/icons/Pdf.svg";
import axios from "../../../../utils/axios";
import { formateDateTime } from "../../../../utils/formattedDateTime";
import { MobileDateTimePicker } from "@mui/x-date-pickers";

// ============= chart section =============
const ChartView = ({ reportFlowAPI, reportLevelAPI, viewDevicesAPI }) => {

  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);

  const [listDevices, setListDevices] = useState([]);
  const [levelDevices, setLevelDevices] = useState([]);
  const [flowType, setFlowType] = useState("Avg Flow"); // Used to set current chart type for flow device
  const [chartData, setChartData] = useState([]); // Used to store actual chart data returned from API
  const [isLoading, setIsLoading] = useState(true); // Used to store if chart it currently loading
  const [filteredData, setFilteredData] = useState(); // Used to store the current device type
  const [startTime, setStartTime] = useState(dayjs(yesterday));
  const [endTime, setEndTime] = useState(dayjs(today));
  const [deviceDetail, setDeviceDetail] = useState();
  const [userType, setUserType] = useState();

  useEffect(() => {
    const currentPath = window.location.pathname;
    if (currentPath.includes("admin")) {
      setUserType("admin");
    } else if (currentPath.includes("dealer")) {
      setUserType("dealer");
    } else {
      setUserType("user");
    }
  }, []);
  const getList = async () => {
    try {
      const getDeviceList = await axios.post(viewDevicesAPI);
      if (getDeviceList?.data?.length > 0) {
        const currentDate = new Date();

        const activeSubscriptions = getDeviceList?.data?.filter((item) => {
          const subscriptionEndDate = new Date(item.subscription_end);
          return subscriptionEndDate > currentDate; // Check if the subscription end date is in the future
        });

        setDeviceDetail(getDeviceList?.data?.[0]);
        setListDevices(activeSubscriptions);
        setFilteredData(activeSubscriptions?.[0]?.device_type);

        const level = activeSubscriptions.filter(
          (item) => item.device_type === "level"
        );
        setLevelDevices(level);
      }
    } catch (error) {}
  };

  useEffect(() => {
    getList();
  }, []);

  const fetchData = async () => {
    console.log(deviceDetail);
    try {
      const modifiedStartTime = startTime.format();
      const modifiedEndTime = endTime.format();

      if (filteredData === "flow") {
        const response = await axios.post(reportFlowAPI, {
          deviceId: [deviceDetail?.id],
          start_date: modifiedStartTime,
          end_date: modifiedEndTime,
          page: -1,
          limit: -1,
        });
        setChartData(response?.data?.[0].data?.results);
      } else {
        const response = await axios.post(reportLevelAPI, {
          deviceId: [deviceDetail?.id],
          start_date: modifiedStartTime,
          end_date: modifiedEndTime,
          page: -1,
          limit: -1,
        });
        setChartData(response?.data?.[0].data?.results);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (deviceDetail?.id) {
      fetchData(); // Fetch data immediately when the component mounts
    }
  }, [deviceDetail, isLoading]);

  // ========= export function ===========
  function exportToExcel(chartData) {
    let chartType;

    if (filteredData === "flow") {
      chartType = chartData.map((data) => ({
        flowrate: data.flowrate,
        totalizer: data.totalizer,
        dateTime: data.date_time,
      }));
    } else {
      chartType = chartData.map((data) => ({
        level: data.level,
        dateTime: data.date_time,
      }));
    }

    const worksheet = XLSX.utils.json_to_sheet(chartType);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Chart Data");
    return workbook;
  }

  function handleExport() {
    const workbook = exportToExcel(chartData);
    XLSX.writeFile(
      workbook,
      `${deviceDetail?.alias_name}_${formateDateTime(new Date())}.xlsx`
    );
  }

  function exportToPDF() {
    const chart = document.getElementById("report-chart");

    html2canvas(chart).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdfWidth = 1522;
      const pdfHeight = 400;
      const pdf = new jsPDF("landscape", "pt", [pdfWidth, pdfHeight]);
      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
      pdf.save(
        `${deviceDetail?.alias_name}_${formateDateTime(new Date())}.pdf`
      );
    });
  }

  return (
    <div>
      <div className="d-flex align-items-center justify-content-between">
        {filteredData === "level" && (
          <FilterDropdown
            options={["Level"]}
            onOptionSelect={(option) => {
              setFlowType(option);
            }}
          />
        )}
        {filteredData === "flow" && (
          <FilterDropdown
            options={["Avg Flow", "Total Flow"]}
            onOptionSelect={(option) => {
              setFlowType(option);
            }}
          />
        )}
        <div className="export-btn">
          Export
          <button onClick={handleExport}>
            <img src={excel} alt="excel" />
          </button>
          <button onClick={exportToPDF}>
            <img src={pdf} alt="pdf" />
          </button>
        </div>
      </div>

      <div id="report-chart" style={{ width: "100%", height: 400 }}>
        <ResponsiveContainer>
          <AreaChart
            data={chartData}
            margin={{
              top: 10,
              right: 30,
              left: 0,
              bottom: 0,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="date_time"
            />
            {filteredData === "flow" ? (
              <YAxis
                dataKey={flowType === "Avg Flow" ? "flowrate" : "totalizer"}
                allowDataOverflow
                domain={
                  flowType === "Avg Flow"
                    ? [
                        0,
                        Math.ceil(
                          Array.isArray(chartData) && chartData?.length > 0
                            ? Math.max(...chartData?.map((o) => o.flowrate)) *
                                1.1
                            : 100
                        ),
                      ]
                    : [
                        0,
                        Math.ceil(
                          Array.isArray(chartData) && chartData?.length > 0
                            ? Math.max(...chartData?.map((o) => o.totalizer)) *
                                1.1
                            : 100
                        ),
                      ]
                }
              />
            ) : (
              <YAxis dataKey="level" />
            )}

            <Tooltip />
            {filteredData === "flow" ? (
              <Area
                type="monotone"
                dataKey={flowType === "Avg Flow" ? "flowrate" : "totalizer"}
                stroke="#09c4ff"
                fill="#09c4ff"
              />
            ) : (
              <Area
                type="monotone"
                dataKey="level"
                stroke="#09c4ff"
                fill="#09c4ff"
              />
            )}
          </AreaChart>
        </ResponsiveContainer>
      </div>

      <div className="date-range flex-wrap d-flex align-items-end">
        <div>
          <label className="input-label">Start date</label>
          <div className="date-input-group">
            <MobileDateTimePicker
              defaultValue={startTime}
              format="DD/MM/YYYY hh:mm A"
              slotProps={{
                textField: {
                  fullWidth: true,
                },
              }}
              value={startTime}
              onChange={setStartTime}
            />
          </div>
        </div>

        <div>
          <label className="input-label">End date</label>
          <div className="date-input-group">
            <MobileDateTimePicker
              defaultValue={startTime}
              format="DD/MM/YYYY hh:mm A"
              value={endTime}
              onChange={setEndTime}
              slotProps={{
                textField: {
                  fullWidth: true,
                },
              }}
            />
          </div>
        </div>

        <button
          onClick={() => {
            fetchData();
            setIsLoading(false);
          }}
          className="white-btn mt-lg-0 mt-3"
        >
          Submit
        </button>
      </div>

      <h6 className="title">active devices</h6>
      <div className="fluid-detail-wrap">
        {listDevices?.map((item, index) => (
          <RadioCard
            key={index}
            name={item.alias_name}
            date={new Date(item.subscription_end).toLocaleDateString("en-US", {
              year: "numeric",
              month: "short",
              day: "numeric",
            })}
            radioName={item.device_type}
            radioId={item.id}
            onClick={() => {
              setDeviceDetail(item);
              setFilteredData(item.device_type);
            }}
            checked={deviceDetail?.id === item.id}
            deviceType={item.device_type}
            userType={userType}
          />
        ))}
      </div>
    </div>
  );
};

export default ChartView;
