import React, { useState, useEffect } from "react";
import { FormControl, FormLabel, FormSelect, Modal } from "react-bootstrap";
import Swal from "sweetalert2";
import axios from "../../../../utils/axios";
import { de } from "date-fns/locale";

const AddDevice = (props) => {
  const [deviceId, setDeviceId] = useState("");
  const [deviceName, setDeviceName] = useState("");
  const [deviceType, setDeviceType] = useState("");
  const [deviceIMEI, setDeviceIMEI] = useState("");
  const [id, setId] = useState("");
  const [dealerList, setDealerList] = useState([]);
  const [dealer, setDealer] = useState("");
  const [depth, setDepth] = useState("");

  const [customerList, setCustomerList] = useState([]);
  const [customer, setCustomer] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (deviceId.includes(" ")) {
        Swal.fire({
          title: "Warning",
          text: "Do not add spaces",
          icon: "warning",
        });
      } else {
        if (
          deviceId === "" ||
          deviceName === "" ||
          deviceType === "" ||
          deviceIMEI === ""
        ) {
          Swal.fire({
            title: "Warning",
            text: "Make sure that Device Id, Device Name, IMEI Number and Device type is not empty.",
            icon: "warning",
          });
        } else {
          await axios
            .post("add/device", {
              deviceId,
              device_type: deviceType,
              alias_name: deviceName,
              imei: `${deviceIMEI}`,
              depth: depth,
            })
            .then((res) => {
              setId(res.data.device.id);
              props.onHide();

              if (customer.length !== 0 && dealer.length === 0) {
                try {
                  axios
                    .post("assign/customer", {
                      deviceId: res.data.device.id,
                      customerId: customer,
                    })
                    .then(() => {
                      Swal.fire({
                        title: "Success",
                        text: "Device is successfully added",
                        icon: "success",
                      });
                    });
                } catch (error) {
                  Swal.fire({
                    title: "Try again",
                    text: "Try again",
                    icon: "error",
                  });
                }
              } else if (dealer.length !== 0 && customer.length === 0) {
                try {
                  axios
                    .post("assign/dealer", {
                      deviceId: res.data.device.id,
                      dealerId: dealer,
                    })
                    .then((res) => {
                      Swal.fire({
                        title: "Success",
                        text: "Device is successfully added",
                        icon: "success",
                      });

                      setDealer("");
                    });
                } catch (error) {
                  Swal.fire({
                    title: "Try Again",
                    text: "Try again",
                    icon: "error",
                  });
                }
              } else if (customer.length !== 0 && dealer.length !== 0) {
                try {
                  axios
                    .post("assign/customer", {
                      deviceId: res.data.device.id,
                      customerId: customer,
                    })
                    .then((res) => {
                      Swal.fire({
                        title: "Success",
                        text: "Device is successfully added",
                        icon: "success",
                      });
                      setCustomer("");
                    });

                  axios
                    .post("assign/dealer", {
                      deviceId: res.data.device.id,
                      dealerId: dealer,
                    })
                    .then((res) => {
                      Swal.fire({
                        title: "Assigned",
                        text: "Device is successfully added",
                        icon: "success",
                      });
                      setDealer("");
                    });
                } catch (error) {
                  Swal.fire({
                    title: "Try Again",
                    text: "Try again",
                    icon: "error",
                  });
                }
              } else {
                Swal.fire({
                  title: "Success",
                  text: `Device is successfully added`,
                  icon: "success",
                  confirmButtonText: "OK",
                });
              }
            });
          setDealer("");
          setDeviceId("");
          setCustomer("");
          setDeviceName("");
          setDeviceType("");
        }
      }
    } catch (error) {
      Swal.fire({
        title: "Error",
        text: "Try again",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };

  const handleReset = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    (async () => {
      try {
        const getCustomerList = await axios.post("view/customers/list");
        setCustomerList(getCustomerList?.data);
      } catch (error) {
        setCustomerList([]);
      }

      try {
        const getDealerList = await axios.post("view/dealers/list");
        setDealerList(getDealerList?.data);
      } catch (error) {
        setDealerList([]);
      }
    })();
  }, []);

  return (
    <Modal
      {...props}
      dialogClassName="modal-75w"
      aria-labelledby="example-custom-modal-styling-title"
    >
      <button className="close-icon" onClick={props.onHide}>
        <svg
          width="31"
          height="31"
          viewBox="0 0 31 31"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M18.4039 15.7032L30.3126 3.81334C30.6685 3.4574 30.8685 2.97463 30.8685 2.47125C30.8685 1.96787 30.6685 1.4851 30.3126 1.12916C29.9567 0.77321 29.4739 0.573242 28.9705 0.573242C28.4671 0.573242 27.9844 0.77321 27.6284 1.12916L15.7386 13.0379L3.84879 1.12916C3.49285 0.77321 3.01008 0.573243 2.5067 0.573243C2.00332 0.573243 1.52055 0.77321 1.16461 1.12916C0.808663 1.4851 0.608695 1.96787 0.608695 2.47125C0.608695 2.97463 0.808663 3.4574 1.16461 3.81334L13.0733 15.7032L1.16461 27.593C0.987436 27.7687 0.846811 27.9778 0.750844 28.2081C0.654878 28.4385 0.605469 28.6855 0.605469 28.9351C0.605469 29.1846 0.654878 29.4317 0.750844 29.662C0.846811 29.8924 0.987436 30.1014 1.16461 30.2772C1.34033 30.4543 1.5494 30.595 1.77975 30.6909C2.01009 30.7869 2.25716 30.8363 2.5067 30.8363C2.75624 30.8363 3.00331 30.7869 3.23366 30.6909C3.464 30.595 3.67307 30.4543 3.84879 30.2772L15.7386 18.3684L27.6284 30.2772C27.8041 30.4543 28.0132 30.595 28.2435 30.6909C28.4739 30.7869 28.721 30.8363 28.9705 30.8363C29.22 30.8363 29.4671 30.7869 29.6975 30.6909C29.9278 30.595 30.1369 30.4543 30.3126 30.2772C30.4898 30.1014 30.6304 29.8924 30.7264 29.662C30.8223 29.4317 30.8717 29.1846 30.8717 28.9351C30.8717 28.6855 30.8223 28.4385 30.7264 28.2081C30.6304 27.9778 30.4898 27.7687 30.3126 27.593L18.4039 15.7032Z"
            fill="black"
          />
        </svg>
      </button>
      <Modal.Body>
        <h4 className="modal-title">Add Device</h4>
        <form action="" className="modal-form">
          <div className="form-fields">
            <div>
              <FormLabel>
                Device Id<span className="text-danger">*</span>
              </FormLabel>
              <FormControl
                required
                value={deviceId}
                onChange={(e) => setDeviceId(e.target.value)}
                placeholder="Enter Device Id....."
              />
            </div>

            <div>
              <FormLabel>
                Device Name<span className="text-danger">*</span>
              </FormLabel>
              <FormControl
                required
                value={deviceName}
                onChange={(e) => setDeviceName(e.target.value)}
                placeholder="Enter Device Name....."
              />
            </div>

            <div>
              <FormLabel>
                Device Type<span className="text-danger">*</span>
              </FormLabel>
              <FormSelect
                required
                value={deviceType}
                onChange={(e) => setDeviceType(e.target.value)}
              >
                <option value="">Select Device Type</option>
                <option value="flow">Flow Device</option>
                <option value="level">Level Device</option>
              </FormSelect>
            </div>

            <div>
              <FormLabel>Customer</FormLabel>
              <FormSelect
                value={customer}
                onChange={(e) => setCustomer(e.target.value)}
              >
                <option value="">Select Customer</option>
                {customerList.map((item) => (
                  <option key={item.customerId} value={item.customerId}>
                    {item.name}
                  </option>
                ))}
              </FormSelect>
            </div>

            <div>
              <FormLabel>Dealer</FormLabel>
              <FormSelect onChange={(e) => setDealer(e.target.value)}>
                <option value="">Select Dealer</option>
                {dealerList.map((item) => (
                  <option key={item.dealerId} value={item.dealerId}>
                    {item.name}
                  </option>
                ))}
              </FormSelect>
            </div>
            <div>
              <FormLabel>
                IMEI Number<span className="text-danger">*</span>
              </FormLabel>
              <FormControl
                required
                type="number"
                value={deviceIMEI}
                onChange={(e) => setDeviceIMEI(e.target.value)}
                placeholder="Enter IMEI number....."
              />
            </div>

            <div>
              <FormLabel>
                Depth of device<span className="text-danger">*</span>
              </FormLabel>
              <FormControl
                required
                type="number"
                value={depth}
                onChange={(e) => setDepth(e.target.value)}
                placeholder="Enter depth of device....."
                defaultValue={0}
              />
            </div>
          </div>

          <div className="form-btn d-flex align-items-center mt-5">
            <button onClick={handleSubmit} type="submit" className="white-btn">
              Save
            </button>
            <button onClick={handleReset} className="white-btn ms-4">
              Reset
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default AddDevice;
