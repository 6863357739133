import React, { useState, useEffect } from "react";
import DeviceFlow from "./DeviceDetail/DeviceFlow";
import { MapContainer, TileLayer, Marker, Tooltip } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import icon from "../../../../assets/icons/maps-and-flags.png";
import DeviceChart from "./DeviceDetail/DeviceChart";
import Devicelevel from "./DeviceDetail/DeviceLevel";
import LevelDeviceChart from "./DeviceDetail/LevelDeviceChart";
import AssignDealer from "./AssignDealer";
import AssignCustomer from "./AssignCustomer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import axios from "../../../../utils/axios";
import { useParams } from "react-router-dom";
import parseNumber from "../../../../utils/parseCalculate";

const AdminDeviceDetail = () => {
  const { id: adminDeviceId } = useParams();
  // data from api
  const [deviceDetail, setDeviceDetail] = useState({});
  const [assignDealer, setAssignDealer] = useState(false);
  const [assignCustomer, setAssignCustomer] = useState(false);
  const [deviceFlowDetail, setDeviceFlowDetail] = useState({});

  const getList = async () => {
    await axios
      .post("view/device/details", {
        deviceId: +adminDeviceId,
      })
      .then((response) => {
        setDeviceDetail(response?.data?.[0]);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    if (adminDeviceId) {
      getList();
    }
  }, [adminDeviceId, assignDealer, assignCustomer]);

  const handleDeviceDetail = async () => {
    try {
      const response = await axios.post(
        deviceDetail?.device_type === "flow"
          ? "admin/view/flow/devices/details/using/array"
          : "admin/view/level/devices/details/using/array",
        {
          id: +adminDeviceId,
        }
      );
      const derivedValue = response?.data?.[0];
      setDeviceFlowDetail({
        flow: parseNumber(
          derivedValue?.data?.flowrate || derivedValue?.data?.level
        ),
        unit: derivedValue?.flow_unit || derivedValue?.level_unit,
        totalFlow: parseNumber(derivedValue?.data?.totalizer),
        totalUnit: derivedValue?.total_unit,
        networkStatus: derivedValue?.data?.network_active,
        battery: derivedValue?.data?.battery,
        deviceType: deviceDetail?.device_type,
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (adminDeviceId && deviceDetail?.device_type) {
      const fetchData = async () => {
        await handleDeviceDetail();
      };

      fetchData(); // Fetch data immediately when the component mounts

      const interval = setInterval(() => {
        fetchData(); // Fetch data and update status every 3 seconds
      }, 3000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [adminDeviceId, deviceDetail]);

  const handleUnassignCustomer = () => {
    axios
      .post("unassign/customer", {
        deviceId: +adminDeviceId,
      })
      .then(() => {
        Swal.fire({
          title: "Success",
          text: "User successfully unassigned",
          icon: "success",
        });

        getList();
      })
      .catch((error) => {
        Swal.fire({
          title: "Try Again",
          text: "Try again",
          icon: "error",
        });
      });
  };

  const handleUnassignDealer = () => {
    axios
      .post("unassign/dealer", {
        deviceId: +adminDeviceId,
      })
      .then(() => {
        Swal.fire({
          title: "Success",
          text: "Dealer successfully unassigned",
          icon: "success",
        });

        getList();
      })
      .catch((error) => {
        Swal.fire({
          title: "Try Again",
          text: "Try again",
          icon: "error",
        });
      });
  };

  let assignedCustomer = "";
  let assignedDealer = "";

  if (deviceDetail.customer_name !== null) {
    assignedCustomer = (
      <>
        {deviceDetail.customer_name}{" "}
        <button onClick={handleUnassignCustomer} className="text-danger fs-6">
          <FontAwesomeIcon icon={faTrash} />
        </button>
      </>
    );
  } else {
    assignedCustomer = (
      <button
        onClick={() => {
          setAssignCustomer(true);
        }}
        className="empty-text"
      >
        Assign Customer
      </button>
    );
  }

  if (deviceDetail.dealer_name !== null) {
    assignedDealer = (
      <>
        {deviceDetail.dealer_name}{" "}
        <button onClick={handleUnassignDealer} className="text-danger fs-6">
          <FontAwesomeIcon icon={faTrash} />
        </button>
      </>
    );
  } else {
    assignedDealer = (
      <button
        onClick={() => {
          setAssignDealer(true);
        }}
        className="empty-text"
      >
        Assign Dealer
      </button>
    );
  }

  return (
    <div className="admin-device-detail">
      {/* <Loading /> */}
      <div className="device-detail-header">
        <div className="user-inner-detail">
          <div>
            <p className="device-name"> {deviceDetail.deviceId} </p>
            <p className="alias-name">
              alias name:{" "}
              <span className="text-primary"> {deviceDetail.alias_name} </span>
            </p>
          </div>

          <div>
            <p className="assignee-detail">Assigned Customer</p>
            <p className="assignee-info text-primary">{assignedCustomer}</p>
          </div>

          <div>
            <p className="assignee-detail">Assigned Dealer</p>
            <p className="assignee-info text-primary">{assignedDealer}</p>
          </div>

          <div>
            <p className="assignee-detail">Subscription End</p>
            <p className="assignee-info text-primary">
              {new Date(deviceDetail.subscription_end).toLocaleDateString(
                "en-US",
                {
                  year: "numeric",
                  month: "short",
                  day: "numeric",
                }
              )}
            </p>
          </div>
        </div>

        <Devicelevel
          id={+adminDeviceId}
          deviceDetail={deviceFlowDetail}
          reportlink="/admin-reports"
        />
      </div>

      <div className="user-detail">
        <div className="chart-area">
          <h5 className="title">Flow Chart</h5>
          {deviceDetail.device_type === "flow" ? (
            <DeviceChart id={+adminDeviceId} />
          ) : (
            <LevelDeviceChart id={+adminDeviceId} />
          )}
        </div>
        <div className="map-area">
          <h5 className="title">Device Map</h5>

          <div className="map-box">
            {deviceDetail.latitude && deviceDetail.longitude ? (
              <MapContainer
                center={[deviceDetail.latitude, deviceDetail.longitude]}
                zoom={8}
                scrollWheelZoom={false}
                style={{ height: "100vh", width: "100%" }}
              >
                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                <Marker
                  position={[deviceDetail.latitude, deviceDetail.longitude]}
                  icon={L.icon({
                    iconUrl: icon,
                    iconSize: [40, 40],
                    iconAnchor: [12, 41],
                    popupAnchor: [1, -34],
                  })}
                >
                  <Tooltip>{deviceDetail.location}</Tooltip>
                </Marker>
              </MapContainer>
            ) : (
              <p className="text-danger mt-5 ms-2 fw-bold fs-5">
                No data for location
              </p>
            )}
          </div>
        </div>
      </div>

      <AssignDealer
        show={assignDealer}
        onHide={() => {
          setAssignDealer(false);
        }}
        id={deviceDetail.id}
      />

      <AssignCustomer
        show={assignCustomer}
        onHide={() => {
          setAssignCustomer(false);
        }}
        id={deviceDetail.id}
      />
    </div>
  );
};

export default AdminDeviceDetail;
