import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import Clock from "../../../utilities/Clock";
import Greeting from "../../../utilities/Greeting";
import Loading from "../../../utilities/Loading";
import AddCustomer from "./AddCustomer";
import AddBtn from "./AddBtn";
import ListItemWrap from "../../Admin/AdminPanel/ListItemWrap";
import AssignCustomer from "./AssignCustomer";
import Swal from "sweetalert2";
import EditCustomer from "../../Admin/AddNewModal/EditCustomer";
import axios from "../../../../utils/axios";

const DealerPanel = () => {
  const [customer, setCustomer] = useState(false);

  const [customerList, setCustomerList] = useState([]);
  const [deviceList, setDeviceList] = useState([]);
  const [activeDevices, setActiveDevices] = useState([]);
  const [inactiveDevices, setInactiveDevices] = useState([]);

  const [editDevice, setEditDevice] = useState(false);
  const [deviceId, setDeviceId] = useState("");

  const [editCustomer, setEditCustomer] = useState(false);

  const [addCustomer, setAddCustomer] = useState(false);
  const [customerId, setCustomerId] = useState("");

  const getList = async () => {
    try {
      const getDealerCustomer = await axios.post("view/dealer/customers");
      console.log(getDealerCustomer);
      setCustomerList(getDealerCustomer?.data);
    } catch (error) {
      console.log(error);
    }
    try {
      const getDeviceDeler = await axios.post("view/devices/dealer");
      setDeviceList(getDeviceDeler?.data);
    } catch (error) {
      console.log(error);
    }
    try {
      const getSubscribedDevices = await axios.post(
        "dealer/view/subscribed/devices"
      );
      setActiveDevices(getSubscribedDevices?.data);
    } catch (error) {
      console.log(error);
    }
    try {
      const getInactiveDevices = await axios.post(
        "dealer/view/subscriptionEnd/devices"
      );
      setInactiveDevices(getInactiveDevices?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getList();
  }, [customer, editCustomer, addCustomer, customerId]);

  const handleDelete = async (id) => {
    await axios
      .post("unassign/dealer", {
        deviceId: id,
      })
      .then(() => {
        Swal.fire({
          title: "Success",
          text: "Device deleted",
          icon: "success",
        });
      })
      .catch((error) => console.log(error));

    getList();
  };

  const addCustomerModal = (id) => {
    setAddCustomer(true);
    setCustomerId(id);
  };

  const unassignCustomer = async (id) => {
    await axios
      .post("unassign/customer", {
        deviceId: id,
      })
      .then(() => {
        Swal.fire({
          title: "Success",
          text: "Customer Unassign successfully",
          icon: "success",
        });
      })
      .catch((error) => console.log(error));

    getList();
  };

  const handleDeleteCustomer = async (id) => {
    await axios
      .post("delete/customer", {
        id,
      })
      .then(() => {
        Swal.fire({
          title: "Success",
          text: "Customer deleted",
          icon: "success",
        });
      })
      .catch((error) => console.log(error));

    getList();
  };

  return (
    <div className="home-screen">
      <Loading />
      <div className="admin-detail">
        <div className="device-detail-wrap">
          {/* ===== greetings::start ===== */}
          <div className="greeting-with-btn d-flex align-items-center justify-content-between">
            <div className="greeting-wrap">
              <div className="greeting-text">
                <Greeting />
                <Clock />
              </div>
            </div>
            <div className="btn-wrap">
              <div className="add-popup-btn-wrap">
                <AddBtn
                  onClick={() => {
                    setCustomer(true);
                  }}
                  icon={
                    <svg
                      width="65"
                      height="66"
                      viewBox="0 0 65 66"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M32.1994 51.7819C28.3331 51.7819 24.6291 52.4165 21.0874 53.6855C17.5457 54.9546 14.1812 56.9468 10.9937 59.6621V60.6361H53.4937V60.1933C50.5423 57.5371 47.222 55.4711 43.5327 53.9954C39.8435 52.5197 36.0657 51.7819 32.1994 51.7819ZM5.68115 57.0944C9.10476 53.7298 13.1039 51.1178 17.6785 49.2585C22.2532 47.3991 27.1082 46.4694 32.2437 46.4694C37.3791 46.4694 42.2193 47.3991 46.7645 49.2585C51.3096 51.1178 55.3235 53.7298 58.8062 57.0944V5.74023H5.68115V57.0944ZM32.2437 38.1465C28.8791 38.1465 26.031 36.9807 23.6994 34.6491C21.3678 32.3175 20.202 29.4694 20.202 26.1048C20.202 22.7402 21.3678 19.8921 23.6994 17.5605C26.031 15.2289 28.8791 14.0632 32.2437 14.0632C35.6082 14.0632 38.4563 15.2289 40.7879 17.5605C43.1195 19.8921 44.2853 22.7402 44.2853 26.1048C44.2853 29.4694 43.1195 32.3175 40.7879 34.6491C38.4563 36.9807 35.6082 38.1465 32.2437 38.1465ZM32.2645 32.834C34.1395 32.834 35.7263 32.1777 37.0249 30.8653C38.3235 29.5527 38.9728 27.959 38.9728 26.084C38.9728 24.209 38.3166 22.6222 37.0041 21.3236C35.6916 20.025 34.0978 19.3757 32.2228 19.3757C30.3478 19.3757 28.761 20.0319 27.4624 21.3444C26.1638 22.6569 25.5145 24.2507 25.5145 26.1256C25.5145 28.0006 26.1707 29.5875 27.4832 30.8861C28.7957 32.1847 30.3895 32.834 32.2645 32.834ZM5.68115 65.9486C4.26449 65.9486 3.0249 65.4173 1.9624 64.3548C0.899902 63.2923 0.368652 62.0527 0.368652 60.6361V5.74023C0.368652 4.32357 0.899902 3.08398 1.9624 2.02148C3.0249 0.958985 4.26449 0.427735 5.68115 0.427735H11.4364L17.1916 0.427734L47.2957 0.427735H53.0509H58.8062C60.2228 0.427735 61.4624 0.958985 62.5249 2.02148C63.5874 3.08398 64.1187 4.32357 64.1187 5.74023V60.6361C64.1187 62.0527 63.5874 63.2923 62.5249 64.3548C61.4624 65.4173 60.2228 65.9486 58.8062 65.9486H5.68115ZM32.2437 60.6361H53.4937H10.9937H32.2437Z"
                        fill="#09C4FF"
                        fillOpacity="0.5"
                      />
                    </svg>
                  }
                  text="add customer"
                />
              </div>
            </div>
          </div>
          {/* ===== greetings::end ===== */}

          {/* ===== listing::start ===== */}
          <Row className="gx-5 listing-wrap">
            <Col xl="6">
              <h5 className="title">device list</h5>
              <div className="device-card">
                {deviceList.map((item) => (
                  <div>
                    <ListItemWrap
                      link={`/dealer-device-listing/device-detail/${item?.id}`}
                      name={item.alias_name}
                      company={item.company_name}
                      deviceType={item.device_type}
                      deviceId={item.id}
                      onUpdate={() => {
                        setEditDevice(true);
                        setDeviceId(item.id);
                      }}
                      onDelete={() => handleDelete(item.id)}
                      isDealer={item.dealer_id !== null ? true : false}
                      isCustomer={item.customer_id !== null ? true : false}
                      assignCustomer={() => addCustomerModal(item.id)}
                      unassignCustomer={() => unassignCustomer(item.id)}
                      deviceCard
                      hideDealer
                      isDealerType
                    />
                  </div>
                ))}
              </div>
            </Col>

            <Col xl="6">
              <h5 className="title">customer list</h5>
              <div className="device-card">
                {customerList.map((item) => (
                  <div>
                    <ListItemWrap
                      link={`/dealer-customer/customer-detail/${item?.id}`}
                      key={item.id}
                      name={item.name}
                      company={item.company_name}
                      assignee={item.deviceCount}
                      date={new Date(item.join_date).toLocaleDateString(
                        "en-US",
                        {
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                        }
                      )}
                      id={item.id}
                      onUpdate={() => {
                        setEditCustomer(true);
                        setCustomerId(item.id);
                      }}
                      onDelete={() => handleDeleteCustomer(item.id)}
                      isDealerType
                    />
                  </div>
                ))}
              </div>
            </Col>
          </Row>
          {/* ===== listing::end ===== */}
        </div>

        <div className="tank-detail-wrap">
          <h5 className="title">active devices</h5>
          <div className="device-card status-card">
            {activeDevices.length === 0 ? (
              <p className="text-danger fw-bold">No devices</p>
            ) : (
              <>
                {activeDevices.map((item) => (
                  <ListItemWrap
                    link={`/dealer-device-listing/device-detail/${item?.id}`}
                    name={item.alias_name}
                    company={item.company_name}
                    deviceType={item.device_type}
                    deviceId={item.id}
                    hideAction
                    isDealerType
                    deviceCard
                    hideDealer
                  />
                ))}
              </>
            )}
          </div>

          <h5 className="title">inactive devices</h5>
          <div className="device-card status-card">
            {inactiveDevices.length === 0 ? (
              <p className="text-danger fw-bold">No devices</p>
            ) : (
              <>
                {inactiveDevices.map((item) => (
                  <ListItemWrap
                    name={item.alias_name}
                    company={item.company_name}
                    deviceType={item.device_type}
                    deviceId={item.id}
                    hideAction
                    isDealerType
                  />
                ))}
              </>
            )}
          </div>
        </div>
      </div>

      <AddCustomer show={customer} onHide={() => setCustomer(false)} />

      <EditCustomer
        show={editCustomer}
        onHide={() => {
          setEditCustomer(false);
          setCustomerId("");
        }}
        id={customerId}
      />

      <AssignCustomer
        show={addCustomer}
        onHide={() => {
          setAddCustomer(false);
          setCustomerId("");
        }}
        id={customerId}
      />
    </div>
  );
};

export default DealerPanel;
