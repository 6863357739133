import React, { useState } from "react";
import ChartView from "../../User/Report/ChartView";
import TableView from "../../User/Report/TableView";

const Report = () => {
  const [activeTab, setActiveTab] = useState("");

  const handleTabCliCk = (tabName) => {
    setActiveTab(tabName);
  };
  return (
    <div className="report-screen">
      {/* <Loading /> */}
      <div className="tab-header">
        <button
          className={`btn btn-tab ${activeTab === "" ? "active" : ""}`}
          onClick={() => handleTabCliCk("")}
        >
          Chart View
        </button>
        <div className="seperator"></div>
        <button
          className={`btn btn-tab ${activeTab === "table" ? "active" : ""}`}
          onClick={() => handleTabCliCk("table")}
        >
          Table View
        </button>
      </div>

      <div className="tab-body">
        {activeTab === "" && (
          <ChartView
            reportFlowAPI="admin/report/flow/devices/betweenDates/disjoint"
            reportLevelAPI="admin/report/level/devices/betweenDates"
            viewDevicesAPI="view/device/list"
          />
        )}
        {activeTab === "table" && (
          <TableView
            viewDeviceAPI="view/device/list"
            flowReportDayAPI="admin/flow/report/devices/betweenDates/group/day"
            levelReportDayAPI="admin/level/report/devices/betweenDates/group/day"
            flowReportMonthAPI="admin/flow/report/devices/betweenDates/group/month"
            levelReportMonthAPI="admin/level/report/devices/betweenDates/group/month"
            flowReportHourAPI="admin/flow/report/devices/betweenDates/group/hour"
            levelReportHourAPI="admin/level/report/devices/betweenDates/group/hour"
            flowReportAPIwithoutFilter="admin/report/flow/devices/betweenDates"
            flowReportAPIforChart="admin/report/flow/devices/betweenDates"
            levelReportAPIwithoutFilter="admin/report/level/devices/betweenDates"
            workHourAPI="admin/report/flow/workhour"
            flowBatteryReport="admin/report/battery/flow"
            levelBatteryReport="admin/report/battery/level"
          />
        )}
      </div>
    </div>
  );
};

export default Report;
