import React, { useState, useEffect } from "react";
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import axios from "../../../../../utils/axios";
import dayjs from "dayjs";

const DeviceChart = ({ id }) => {
  const [chartData, setChartData] = useState([]);

  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);

  const [startTime, setStartTime] = useState(dayjs(yesterday));
  const [endTime, setEndTime] = useState(dayjs(today));

  const fetchData = async () => {
    const modifiedStartTime = startTime.format();
    const modifiedEndTime = endTime.format();
    try {
      const response = await axios.post(
        'admin/flow/report/devices/betweenDates/group/hour',
        {
          deviceId: id,
          start_date: modifiedStartTime,
          end_date: modifiedEndTime,
        }
      );
      setChartData(response.data[0].data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [id]);
  return (
    <div style={{ width: "100%", height: 400 }}>
      <ResponsiveContainer>
        <AreaChart
          data={chartData}
          margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 0,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey={(data) => data.date.substr(0, 10)} />
          <YAxis dataKey="avg_flowrate" />
          <Tooltip />
          <Area
            type="monotone"
            dataKey="avg_flowrate"
            stroke="#09c4ff"
            fill="#09c4ff"
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

export default DeviceChart;
