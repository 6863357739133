import React, { useEffect, useState } from "react";
import DeviceCard from "../../Admin/DeviceListing/DeviceCard";
import FilterDropdown from "../../../utilities/FilterDropdown";
import Swal from "sweetalert2";
import AssignCustomer from "../DealerPanel/AssignCustomer";
import axios from "../../../../utils/axios";

const DealerDevices = () => {
  const [activeTab, setActiveTab] = useState("");
  const [deviceList, setDeviceList] = useState([]);
  const [addNew, setAddNew] = useState(false);

  const [deviceDetail, setDeviceDetail] = useState([]);
  const [show, setShow] = useState(false);
  const [name, setName] = useState("");
  const [id, setId] = useState("");
  const [assignModal, setAssignModal] = useState(false);
  const [deviceId, setDeviceId] = useState(false);

  const [sortOrder, setSortOrder] = useState("");
  const [deviceStatusMap, setDeviceStatusMap] = useState({});

  const headerToken = localStorage.getItem("accessToken");

  const handleSortChange = (selectedOption) => {
    setSortOrder(selectedOption);
  };

  const sortedDevices = () => {
    let filteredDevices = deviceList;

    if (activeTab === "active") {
      filteredDevices = filteredDevices.filter(
        (device) => new Date(device.subscription_end) >= new Date()
      );
    } else if (activeTab === "inactive") {
      filteredDevices = filteredDevices.filter(
        (device) => new Date(device.subscription_end) < new Date()
      );
    }

    if (activeTab === "unassign") {
      filteredDevices = filteredDevices.filter(
        (item) => item.customer_id === null
      );
    } else if (activeTab === "assign") {
      filteredDevices = filteredDevices.filter(
        (item) => item.customer_id !== null
      );
    }

    if (activeTab === "flow") {
      filteredDevices = filteredDevices.filter(
        (item) => item.device_type === "flow"
      );
    } else if (activeTab === "level") {
      filteredDevices = filteredDevices.filter(
        (item) => item.device_type === "level"
      );
    }

    if (sortOrder === "Name A-Z") {
      return filteredDevices.sort((a, b) =>
        a.alias_name.localeCompare(b.alias_name)
      );
    } else if (sortOrder === "Name Z-A") {
      return filteredDevices.sort((a, b) =>
        b.alias_name.localeCompare(a.alias_name)
      );
    } else {
      return filteredDevices;
    }
  };

  const handleDeviceStatusUpdate = (id, status) => {
    setDeviceStatusMap((prevStatusMap) => ({
      ...prevStatusMap,
      [id]: status,
    }));
  };

  const getList = async () => {
    try {
      const getDeviceDealer = await axios.post("view/devices/dealer");
      setDeviceList(getDeviceDealer?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getList();
  }, []);

  const handleTabCliCk = (tabName) => {
    setActiveTab(tabName);
  };

  const handleClose = () => {
    setAddNew(false);
    setAssignModal(false);
    getList();
  };

  const handleDelete = async (id) => {
    await axios
      .post("unassign/dealer", {
        deviceId: id,
      })
      .then((response) => {
        Swal.fire({
          title: "Success",
          text: "Device Successfully deleted",
          icon: "success",
        });
      })
      .catch((error) => console.log(error));

    getList();
  };

  useEffect(() => {
    setName(deviceDetail.alias_name);
    setId(deviceDetail.deviceId);
  }, [deviceDetail]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await axios
        .post("change/device/details", {
          id: deviceDetail.id,
          alias_name: name,
        })
        .then((res) => {
          Swal.fire({
            title: "Success",
            text: "Details are successfully updated",
            icon: "success",
          });
        });

      await axios.post("change/deviceId", {
        deviceId: id,
        id: deviceDetail.id,
      });

      setShow(false);
      getList();
    } catch (error) {
      console.log(error);
    }
  };

  const handleAssign = async (id) => {
    setAssignModal(true);
    setDeviceId(id);
  };

  useEffect(() => {
    getList();
  }, [assignModal, addNew]);
  return (
    <>
      {/* <Loading /> */}
      <div className="listing-page">
        {/* tab header */}
        <div className="tab-header">
          <button
            className={`btn btn-tab ${activeTab === "" ? "active" : ""}`}
            onClick={() => handleTabCliCk("")}
          >
            All
          </button>

          <div className="seperator"></div>

          <button
            className={`btn btn-tab ${activeTab === "active" ? "active" : ""}`}
            onClick={() => handleTabCliCk("active")}
          >
            Active
          </button>
          <button
            className={`btn btn-tab ${
              activeTab === "inactive" ? "active" : ""
            }`}
            onClick={() => handleTabCliCk("inactive")}
          >
            Inactive
          </button>

          <div className="seperator"></div>

          <button
            className={`btn btn-tab ${activeTab === "assign" ? "active" : ""}`}
            onClick={() => handleTabCliCk("assign")}
          >
            Assign
          </button>
          <button
            className={`btn btn-tab ${
              activeTab === "unassign" ? "active" : ""
            }`}
            onClick={() => handleTabCliCk("unassign")}
          >
            Unassign
          </button>

          <div className="seperator"></div>

          <button
            className={`btn btn-tab ${activeTab === "flow" ? "active" : ""}`}
            onClick={() => handleTabCliCk("flow")}
          >
            Flow
          </button>
          <button
            className={`btn btn-tab ${activeTab === "level" ? "active" : ""}`}
            onClick={() => handleTabCliCk("level")}
          >
            Level
          </button>
        </div>
        {/* tab header */}

        {/* page sorting and info */}
        <div className="device-head d-flex align-items-center justify-content-between">
          <h3 className="title">device list</h3>

          <div className="right-btn-wrap d-flex align-items-center justify-content-end">
            <FilterDropdown
              placeholder="Sort By"
              options={["Name A-Z", "Name Z-A"]}
              onOptionSelect={(option) => handleSortChange(option)}
              onChange={handleSortChange}
            />
          </div>
        </div>
        {/* page sorting and info */}

        {/* tab body */}
        <div className="tab-body">
          {sortedDevices()?.map((item) => (
            <div>
              <DeviceCard
                link={`/dealer-device-listing/device-detail/${item?.id}`}
                onStatusUpdate={handleDeviceStatusUpdate}
                deviceType={item.device_type}
                deviceId={item.id}
                name={item.alias_name}
                company={item.company_name}
                endDate={new Date(item.subscription_end).toLocaleDateString(
                  "en-US",
                  {
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                  }
                )}
                onDelete={() => {
                  handleDelete(item.id);
                }}
                onAssign={() => handleAssign(item.id)}
                assignee={
                  item.customer_id !== null || item.dealer_id !== null
                    ? true
                    : false
                }
                hideUpdate
                isAdmin={false}
              />
            </div>
          ))}
        </div>
        {/* tab body */}
      </div>

      <AssignCustomer show={assignModal} onHide={handleClose} id={deviceId} />
    </>
  );
};

export default DealerDevices;
