import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { TableVirtuoso } from "react-virtuoso";
import { displayTimeDifferenceBetweenDates } from "../../../../utils/displayTimeDifferenceBetweenDates";

const VirtuosoTableComponents = {
  Scroller: React.forwardRef((props, ref) => (
    <TableContainer component={Paper} {...props} ref={ref} />
  )),
  Table: (props) => (
    <Table
      {...props}
      sx={{ borderCollapse: "separate", tableLayout: "fixed" }}
    />
  ),
  TableHead,
  TableRow: ({ item: _item, ...props }) => <TableRow {...props} />,
  TableBody: React.forwardRef((props, ref) => (
    <TableBody {...props} ref={ref} />
  )),
};

function fixedHeaderContent(columns) {
  return (
    <TableRow>
      {columns.map((column) => (
        <TableCell
          key={column.id}
          variant="head"
          align="center"
          style={{ width: column.width }}
          sx={{
            backgroundColor: "#09c4ff",
            fontSize: "24px",
            fontWeight: 400,
            marginBottom: "10px",
            padding: "11px",
          }}
        >
          {column.lable}
        </TableCell>
      ))}
    </TableRow>
  );
}


function rowContent(_index, row, columns, interval, reportType) {
  return (
    <React.Fragment>
      {columns.map((column, index) => (
        <TableCell key={`${column.id} + ${index}`} align="center">
              <span className="date from">
                {row[column.dataKey]}
              </span>
        </TableCell>
      ))}
    </React.Fragment>
  );
}

export default function ReactVirtualizedTable({
  rows,
  columns,
  reportType,
  interval,
}) {
  return (
    <Paper style={{ height: 700, width: "100%" }}>
      <TableVirtuoso
        data={rows}
        components={VirtuosoTableComponents}
        fixedHeaderContent={() => fixedHeaderContent(columns)}
        itemContent={(index, data) =>
          rowContent(index, data, columns, reportType, interval)
        }
      />
    </Paper>
  );
}
