import React, { useState, useEffect } from "react";
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import axios from "../../../utils/axios";

const DeviceChart = ({ id }) => {
  const [chartData, setChartData] = useState([]);

  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);

  const [start, setStart] = useState(yesterday.toISOString().slice(0, 10));
  const [end, setEnd] = useState(today.toISOString().slice(0, 10));

  const clockOption = { hour: "numeric", minute: "numeric", hour12: false };
  const [startTime, setStartTime] = useState(
    new Date().toLocaleTimeString(undefined, clockOption)
  );
  const [endTime, setEndTime] = useState(
    new Date().toLocaleTimeString(undefined, clockOption)
  );

  const fetchData = async () => {
    try {
      const response = await axios.post("level/report/devices/last24hour", {
        deviceId: id,
        start_date: `${start} ${startTime}`,
        end_date: `${end} ${endTime}`,
      });
      setChartData(response?.data?.[0]?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const fetchChartData = async () => {
      await fetchData();
    };

    fetchChartData(); // Fetch data immediately when the component mounts

    const interval = setInterval(() => {
      fetchChartData(); // Fetch data and update status every 3 seconds
    }, 3000);

    return () => {
      clearInterval(interval);
    };
  }, [id]);
  return (
    <div style={{ width: "100%", height: 400 }}>
      <ResponsiveContainer>
        <AreaChart
          data={chartData}
          margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 0,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="hour" />
          <YAxis dataKey="avg_level" />
          <Tooltip />
          <Area
            type="monotone"
            dataKey="avg_level"
            stroke="#09c4ff"
            fill="#09c4ff"
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

export default DeviceChart;
