import React, { useEffect, useState } from "react";
import parseNumber from "../../../../utils/parseCalculate";
import axios from "../../../../utils/axios";
import { Box } from "@mui/material";
import FlowDevice from "./Devices/FlowDevice";

function Item(props) {
  const { sx, ...other } = props;
  return <Box {...other} />;
}

const FluidWrap = ({ index, pinned, onPinClick, name, deviceId }) => {
  const [deviceDetail, setDeviceDetail] = useState();
  const [deviceStatus, setDeviceStatus] = useState();
  const [flow, setFlow] = useState("--");
  const [totalFlow, setTotalFlow] = useState("--");

  const handlePinClick = () => {
    const newPinned = !pinned;
    onPinClick(parseInt(index), Boolean(newPinned));
    localStorage.setItem(index.toString(), newPinned.toString());
  };

  const handleDeviceDetail = async (id) => {
    try {
      const response = await axios.post("view/flow/lastdata", {
        deviceId: id,
      });
      const derivedResponse = response?.data;
      if (derivedResponse?.data !== undefined) {
        const targetTime = new Date(derivedResponse?.data?.date_time);
        const currentTime = new Date();

        // Calculate the difference between the two times in milliseconds
        const timeDiff = currentTime - targetTime;
        // Check if the difference is less than 3 seconds (300000 milliseconds)
        if (timeDiff < 30000) {
          setDeviceStatus("active");
        } else {
          setDeviceStatus("inactive");
        }
      } else {
        setDeviceStatus("inactive");
      }
      setDeviceDetail(derivedResponse);
      if (derivedResponse !== undefined) {
        setFlow(parseNumber(derivedResponse?.flowrate));
        setTotalFlow(derivedResponse?.totalizer);
      } else {
        setFlow("--");
        setTotalFlow("--");
      }
      return derivedResponse;
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await handleDeviceDetail(deviceId);
    };

    fetchData(); // Fetch data immediately when the component mounts

    const interval = setInterval(() => {
      fetchData(); // Fetch data and update status every 3 seconds
    }, 3000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (deviceDetail) {
      const origanlFlowValue = parseNumber(deviceDetail?.data?.flowrate);
      const origanlTotalFlowValue = parseNumber(deviceDetail?.data?.totalizer);
      setFlow(origanlFlowValue);
      setTotalFlow(origanlTotalFlowValue);
    }
  }, [deviceDetail]);

  return (
    <Item>
      <FlowDevice
        deviceStatus={deviceStatus}
        flowValue={`${flow} ${deviceDetail?.flow_unit}`}
        totalFlowValue={`${totalFlow} ${deviceDetail?.total_unit}`} // Temporarily set unit to static liter
        lastUpdated={`
          ${
            deviceDetail?.data?.date_time
              ? new Date(deviceDetail?.data?.date_time)
                  .toLocaleDateString("en-GB")
                  .slice(0, 10)
              : "--"
          } ${
          deviceDetail?.data?.date_time
            ? new Date(deviceDetail?.data?.date_time).toLocaleTimeString(
                "en-US",
                {
                  hour12: true,
                }
              )
            : "--"
        }`}
        powerStatus={deviceDetail?.data?.battery}
        title={name}
      />
    </Item>
  );
};

export default FluidWrap;
