 const parseNumber = (value) => {
    const afterParsed =
      value !== undefined && value !== null 
        ? parseFloat(value)
            .toFixed(2)
            .replace(/\.?0+$/, "")
        : "--";
    return afterParsed;
  };

  export default parseNumber