import { Tooltip } from "@mui/material";
import batteryOff from "../../../../../assets/img/Battery-off-transparent.png";
import batteryOn from "../../../../../assets/img/Battery-on-transparent.png";
import {
  DeviceConnectedLogo,
  DeviceDisconnectedLogo,
} from "../../../../Logo/logos";
const FlowDevice = ({
  title,
  deviceStatus,
  flowValue,
  totalFlowValue,
  lastUpdated,
  powerStatus,
}) => {
  return (
    <div
      style={{
        width: 350,
        height: 300,
        backgroundImage:
          deviceStatus === "active"
            ? "url(/images/flow.gif)"
            : "url(/images/flow.png)",
      }}
      className="device-card-flow"
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div style={{ fontSize: "2rem", color: "#1C5E85" }}>
          {title?.length > 14 ? title?.substring(0, 10) + "..." : title}
        </div>
        <div style={{ display: "flex", gap: "10px", paddingLeft: 1 }}>
          <>
            {deviceStatus === "active" ? (
              <Tooltip title="Device Connected">
                <DeviceConnectedLogo />
              </Tooltip>
            ) : (
              <Tooltip title="Device Disconnected">
                <DeviceDisconnectedLogo />
              </Tooltip>
            )}
            {powerStatus === 1 ? (
              <Tooltip title="Power On">
                <img
                  src={batteryOn}
                  alt="power-on"
                  style={{ height: "40px" }}
                />
              </Tooltip>
            ) : (
              <Tooltip title="Power Off">
                <img
                  src={batteryOff}
                  alt="power-off"
                  style={{ height: "40px" }}
                />
              </Tooltip>
            )}
          </>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: 10,
        }}
      >
        <div>
          <div style={{ fontSize: "1rem" }}>Flow:</div>
          <div
            style={{
              fontSize: "1.2rem",
              color: "#09c4ff",
              fontWeight: "normal",
            }}
          >
            {flowValue}
          </div>
        </div>
        <div style={{ marginLeft: "13px" }}>
          <div style={{ fontSize: "1rem" }}>Totalizer:</div>
          <div
            style={{
              fontSize: "1.2rem",
              color: "#09c4ff",
              fontWeight: "normal",
            }}
          >
            {totalFlowValue}
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: 10,
        }}
      >
        <div>
          <div style={{ fontSize: "1rem" }}>Last Updated:</div>
          <div
            style={{
              fontSize: "1.2rem",
              color: "#09c4ff",
              fontWeight: "normal",
            }}
          >
            {lastUpdated}
          </div>
        </div>
      </div>
    </div>
  );
};
export default FlowDevice;
