import React, { useState, useEffect } from "react";
import { MapContainer, TileLayer, Marker, Tooltip } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import icon from "../../assets/icons/maps-and-flags.png";
import DeviceChart from "./DeviceDetail/DeviceChart";
import LevelDeviceChart from "./DeviceDetail/LevelDeviceChart";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { FormControl, FormLabel, Modal } from "react-bootstrap";
import Swal from "sweetalert2";
import axios from "../../utils/axios";
import { useParams } from "react-router-dom";
import Devicelevel from "../pages/Admin/DeviceDetail/DeviceDetail/DeviceLevel";
import parseNumber from "../../utils/parseCalculate";

const DeviceDetail = () => {
  // data from api
  const [deviceId, setDeviceId] = useState("");
  const [aliasName, setAliasName] = useState("");
  const [endDate, setEndDate] = useState("");
  const [assignee, setAssignee] = useState("");
  const [assignedDate, setAssignedDate] = useState("");
  const [lat, setLat] = useState();
  const [long, setLong] = useState();
  const [type, setType] = useState();
  const [input, setInput] = useState("");
  const [show, setShow] = useState(false);
  const [deviceDetail, setDeviceDetail] = useState();
  const [flowDeviceDetail, setFlowDeviceDetail] = useState();

  const { id: deviceDetailId } = useParams();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const getList = async () => {
    try {
      const getDeviceList = await axios.post("view/devices");
      if (getDeviceList?.data?.length > 0) {
        const derivedValue = getDeviceList?.data;
        const flowDevices = derivedValue?.filter(
          (item) => item?.id === +deviceDetailId
        );
        setDeviceId(flowDevices?.[0]?.deviceId);
        setAliasName(flowDevices?.[0]?.alias_name);
        setInput(flowDevices?.[0]?.alias_name);
        setEndDate(flowDevices?.[0]?.subscription_end);
        setAssignee(flowDevices?.[0]?.customer_name);
        setAssignedDate(flowDevices?.[0]?.assigned_at);
        setLat(flowDevices?.[0]?.latitude);
        setLong(flowDevices?.[0]?.longitude);
        setType(flowDevices?.[0]?.device_type);
        setDeviceDetail(flowDevices?.[0]);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getList();
  }, [deviceDetailId]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (aliasName === "") {
      Swal.fire({
        title: "Try Again",
        text: "All fields are mandatory",
        icon: "warning",
      });
    } else {
      try {
        await axios.post("add/aliasname/device", {
          deviceId: +deviceDetailId,
          alias_name: input,
        });

        Swal.fire({
          title: "Changed",
          text: "Alias Name has been changed",
          icon: "success",
        });

        setShow(false);
        getList();
      } catch (error) {
        Swal.fire({
          title: "Try Again",
          text: "Try again",
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    }
  };

  const handleReset = (e) => {
    e.preventDefault();
    setInput(deviceDetail.alias_name);
  };

  const handleDeviceDetail = async () => {
    try {
      if (deviceDetailId) {
        const response = await axios.post(
          type === "flow" ? "view/flow/lastdata" : "view/level/lastdata",
          {
            deviceId: +deviceDetailId,
          }
        );
        const result = response?.data;
        setFlowDeviceDetail({
          flow: parseNumber(result?.data?.flowrate || result?.data?.level),
          unit: result?.flow_unit || result?.level_unit,
          totalFlow: parseNumber(result?.data?.totalizer),
          totalUnit: result?.total_unit,
          networkStatus: result?.data?.network_active,
          battery: result?.data?.battery,
          deviceType: deviceDetail?.device_type,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (deviceDetailId && type) {
      const fetchData = async () => {
        await handleDeviceDetail();
      };

      fetchData(); // Fetch data immediately when the component mounts

      const interval = setInterval(() => {
        fetchData(); // Fetch data and update status every 3 seconds
      }, 3000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [deviceDetailId, type]);

  return (
    <div>
      {/* <Loading /> */}
      <div className="device-detail-header">
        <div className="user-inner-detail">
          <div>
            <p className="device-name"> {deviceId} </p>
            <p className="alias-name">
              alias name: <span className="text-primary me-3">{aliasName}</span>
              <FontAwesomeIcon
                className=" cursor-pointer text-primary"
                onClick={handleShow}
                icon={faPenToSquare}
              />
            </p>
          </div>

          <div>
            <p className="assignee-detail">Assigned to</p>
            <p className="assignee-info text-primary">{assignee}</p>
          </div>

          <div>
            <p className="assignee-detail">Assigned date</p>
            <p className="assignee-info text-primary">
              {new Date(assignedDate).toLocaleDateString("en-US", {
                year: "numeric",
                month: "short",
                day: "numeric",
              })}
            </p>
          </div>

          <div>
            <p className="assignee-detail">Subscription End</p>
            <p className="assignee-info text-primary">
              {new Date(endDate).toLocaleDateString("en-US", {
                year: "numeric",
                month: "short",
                day: "numeric",
              })}
            </p>
          </div>
        </div>

        <Devicelevel
          id={+deviceDetailId}
          deviceDetail={flowDeviceDetail}
          reportlink="/report"
        />
      </div>

      <div className="user-detail">
        <div className="chart-area">
          <h5 className="title">Flow Chart</h5>
          {type === "flow" ? (
            <DeviceChart id={+deviceDetailId} />
          ) : (
            <LevelDeviceChart id={+deviceDetailId} />
          )}
        </div>
        <div className="map-area">
          <h5 className="title">Device Map</h5>

          <div className="map-box">
            {lat && long ? (
              <MapContainer
                center={[lat, long]}
                zoom={8}
                scrollWheelZoom={false}
                style={{ height: "100vh", width: "100%" }}
              >
                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                <Marker
                  position={[lat, long]}
                  icon={L.icon({
                    iconUrl: icon,
                    iconSize: [40, 40],
                    iconAnchor: [12, 41],
                    popupAnchor: [1, -34],
                  })}
                >
                  <Tooltip>{aliasName}</Tooltip>
                </Marker>
              </MapContainer>
            ) : (
              <p className="text-danger mt-5 ms-2 fw-bold fs-5">
                No data for location
              </p>
            )}
          </div>
        </div>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="modal-75w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <button className="close-icon" onClick={handleClose}>
          <svg
            width="31"
            height="31"
            viewBox="0 0 31 31"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18.4039 15.7032L30.3126 3.81334C30.6685 3.4574 30.8685 2.97463 30.8685 2.47125C30.8685 1.96787 30.6685 1.4851 30.3126 1.12916C29.9567 0.77321 29.4739 0.573242 28.9705 0.573242C28.4671 0.573242 27.9844 0.77321 27.6284 1.12916L15.7386 13.0379L3.84879 1.12916C3.49285 0.77321 3.01008 0.573243 2.5067 0.573243C2.00332 0.573243 1.52055 0.77321 1.16461 1.12916C0.808663 1.4851 0.608695 1.96787 0.608695 2.47125C0.608695 2.97463 0.808663 3.4574 1.16461 3.81334L13.0733 15.7032L1.16461 27.593C0.987436 27.7687 0.846811 27.9778 0.750844 28.2081C0.654878 28.4385 0.605469 28.6855 0.605469 28.9351C0.605469 29.1846 0.654878 29.4317 0.750844 29.662C0.846811 29.8924 0.987436 30.1014 1.16461 30.2772C1.34033 30.4543 1.5494 30.595 1.77975 30.6909C2.01009 30.7869 2.25716 30.8363 2.5067 30.8363C2.75624 30.8363 3.00331 30.7869 3.23366 30.6909C3.464 30.595 3.67307 30.4543 3.84879 30.2772L15.7386 18.3684L27.6284 30.2772C27.8041 30.4543 28.0132 30.595 28.2435 30.6909C28.4739 30.7869 28.721 30.8363 28.9705 30.8363C29.22 30.8363 29.4671 30.7869 29.6975 30.6909C29.9278 30.595 30.1369 30.4543 30.3126 30.2772C30.4898 30.1014 30.6304 29.8924 30.7264 29.662C30.8223 29.4317 30.8717 29.1846 30.8717 28.9351C30.8717 28.6855 30.8223 28.4385 30.7264 28.2081C30.6304 27.9778 30.4898 27.7687 30.3126 27.593L18.4039 15.7032Z"
              fill="black"
            />
          </svg>
        </button>
        <Modal.Body>
          <h4 className="modal-title">Edit Alias Name</h4>
          <form action="" className="modal-form">
            <div className="form-fields">
              <div>
                <FormLabel>Alias Name</FormLabel>
                <FormControl
                  value={input}
                  onChange={(e) => setInput(e.target.value)}
                  placeholder="Enter Alias Name....."
                />
              </div>
            </div>

            <div className="form-btn d-flex align-items-center mt-5">
              <button
                onClick={handleSubmit}
                type="submit"
                className="white-btn"
              >
                Save
              </button>
              <button onClick={handleReset} className="white-btn ms-4">
                Reset
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default DeviceDetail;
