import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../../../assets/img/logo.svg";
import axios from "../../../../utils/axios";

const Header = () => {
  const [search, setSearch] = useState(false);
  const navigate = useNavigate();
  const ref = useRef();

  const [SearchInput, setSearchInput] = useState("");
  const [listDevices, setListDevices] = useState([]);
  const [dealerList, setDealerList] = useState([]);
  const [customerList, setCustomerList] = useState([]);

  const [dealers, setDealers] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [devices, setDevices] = useState([]);

  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setSearch(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  const handleLogout = () => {
    navigate("/login");
    localStorage.clear();
  };

  const getList = async () => {
    try {
      const getDeviceList = await axios.post("view/device/list");
      setListDevices(getDeviceList?.data);
    } catch (error) {
      console.log(error);
    }
    try {
      const getDealersList = await axios.post("view/dealers/list");
      setDealerList(getDealersList?.data);
    } catch (error) {
      console.log(error);
    }
    try {
      const getCustomerList = await axios.post("view/customers/list");
      setCustomerList(getCustomerList?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getList();
  }, []);

  // let filteredData = [];
  // useEffect(() => {
  //   if (SearchInput !== "") {
  //     filteredData = [
  //       ...listDevices.filter((item) =>
  //         item.alias_name.toLowerCase().includes(SearchInput.toLowerCase())
  //       ),
  //       ...dealerList.filter((item) =>
  //         item.name.toLowerCase().includes(SearchInput.toLowerCase())
  //       ),
  //       ...customerList.filter((item) =>
  //         item.name.toLowerCase().includes(SearchInput.toLowerCase())
  //       ),
  //     ];
  //   }

  //   const filteredDealers = filteredData.filter((item) => item.dealerId);
  //   const filteredCustomers = filteredData.filter((item) => item.customerId);
  //   const filteredDevices = filteredData.filter((item) => item.deviceId);

  //   setDealers(filteredDealers);
  //   setCustomers(filteredCustomers);
  //   setDevices(filteredDevices);
  // }, [SearchInput, listDevices, dealerList, customerList]);

  useEffect(() => {
    let modifiedFilteredData = [];

    if (SearchInput !== "") {
      modifiedFilteredData = [
        ...listDevices.filter((item) =>
          item.alias_name.toLowerCase().includes(SearchInput.toLowerCase())
        ),
        ...dealerList.filter((item) =>
          item.name.toLowerCase().includes(SearchInput.toLowerCase())
        ),
        ...customerList.filter((item) =>
          item.name.toLowerCase().includes(SearchInput.toLowerCase())
        ),
      ];
    }

    setFilteredData(modifiedFilteredData);

    const filteredDealers = modifiedFilteredData?.filter(
      (item) => item.dealerId
    );
    const filteredCustomers = modifiedFilteredData?.filter(
      (item) => item.customerId
    );
    const filteredDevices = modifiedFilteredData?.filter(
      (item) => item.deviceId
    );

    setDealers(filteredDealers);
    setCustomers(filteredCustomers);
    setDevices(filteredDevices);
  }, [SearchInput, listDevices, dealerList, customerList]);

  const searchResult = filteredData.map((item) => {
    if (item.deviceId) {
      return (
        <Link
          to={`/device-listing/device-detail/${item?.id}`}
          className="searched-device"
          key={item.id}
        >
          {item.alias_name}
        </Link>
      );
    } else if (item.dealerId) {
      return (
        <Link
          to={`/dealer-listing/dealer-detail/${item?.id}`}
          className="searched-device"
          key={item.id}
        >
          {item.name}
        </Link>
      );
    } else if (item.customerId) {
      return (
        <Link
          to={`/customer-listing/customer-detail/${item?.customerId}`}
          className="searched-device"
          key={item.id}
        >
          {item.name}
        </Link>
      );
    }
  });

  return (
    <>
      <div
        className={`header-wrap d-flex align-items-center justify-content-between`}
      >
        {/* logo::start */}
        <Link className="logo-wrap" to="/admin-panel">
          <img src={logo} alt="flowdo" className="logo" />
        </Link>
        {/* logo::end */}

        {/* right menu::start */}
        <div className="right-menu-wrap d-flex align-items-center justify-content-end">
          {/* search bar::start */}
          <div className={`searchbar-box-wrap ${search ? "active" : ""}`}>
            <div ref={ref}>
              <div
                onClick={() => setSearch(!search)}
                className={`searchbar-wrap d-flex align-items-center ${
                  search ? "active" : ""
                }`}
              >
                <input
                  type="text"
                  id="searchbar"
                  className="search-area"
                  placeholder="Search......"
                  value={SearchInput}
                  onChange={(e) => setSearchInput(e.target.value)}
                  onClick={(e) => {
                    e.stopPropagation();
                    setSearch(true);
                  }}
                  autocomplete="off"
                />
                <label htmlFor="searchbar" className="search-icon">
                  <svg
                    width="48"
                    height="49"
                    viewBox="0 0 48 49"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M20.775 35.8252C12.75 35.8252 6.15002 29.3002 6.15002 21.2002C6.15002 13.1752 12.675 6.5752 20.775 6.5752C28.875 6.5752 35.4 13.1002 35.4 21.2002C35.325 29.3002 28.8 35.8252 20.775 35.8252ZM20.775 8.7502C13.875 8.7502 8.25002 14.3752 8.25002 21.2752C8.25002 28.1752 13.875 33.8002 20.775 33.8002C27.675 33.8002 33.3 28.1752 33.3 21.2752C33.225 14.3752 27.6 8.7502 20.775 8.7502Z"
                      fill="black"
                    />
                    <path
                      d="M40.2751 42.3502L30.0751 32.2252L31.7251 30.5752L41.8501 40.7752L40.2751 42.3502Z"
                      fill="black"
                    />
                  </svg>
                </label>
              </div>

              {SearchInput !== "" && search && (
                <div className="searched-data">
                  {filteredData.length !== 0 ? (
                    searchResult
                  ) : (
                    <p className="empty-msg">No Data Found</p>
                  )}
                </div>
              )}
            </div>
          </div>
          {/* search bar::end */}

          <div className="my-account">
            <p onClick={handleLogout} className="account-menu">
              Logout
            </p>
          </div>
        </div>
        {/* right menu::end */}
      </div>
    </>
  );
};

export default Header;
