import { Tooltip } from "@mui/material";
import batteryOff from "../../../../../assets/img/Battery-off-transparent.png";
import batteryOn from "../../../../../assets/img/Battery-on-transparent.png";

import {
  DeviceConnectedLogo,
  DeviceDisconnectedLogo,
} from "../../../../Logo/logos";
const LevelDevice = ({
  title,
  deviceStatus,
  levelValue,
  lastUpdatedDate,
  lastUpdatedTime,
  powerStatus,
}) => {
  return (
    <div
      style={{
        width: 350,
        height: 300,
        backgroundImage: "url(/images/level-static.png)",
      }}
      className="device-card-level"
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ fontSize: "2rem", color: "#1C5E85" }}>{title}</div>
        <div>
          <>
            {deviceStatus === "active" ? (
              <Tooltip title="Device Connected">
                <DeviceConnectedLogo />
              </Tooltip>
            ) : (
              <Tooltip title="Device Disconnected">
                <DeviceDisconnectedLogo />
              </Tooltip>
            )}
            {powerStatus === 1 && (
              <Tooltip title="Power On">
                <img
                  src={batteryOn}
                  alt="power-on"
                  style={{ height: "40px" }}
                />
              </Tooltip>
            )}
            {powerStatus === 0 ||
              (powerStatus === undefined && (
                <Tooltip title="Power Off">
                  <img
                    src={batteryOff}
                    alt="power-off"
                    style={{ height: "40px" }}
                  />
                </Tooltip>
              ))}
          </>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: 30,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            gap: 20,
          }}
        >
          <div>
            <div style={{ fontSize: "1rem" }}>Level</div>
            <div
              style={{
                fontSize: "1.2rem",
                color: "#09c4ff",
                fontWeight: "normal",
              }}
            >
              {levelValue}
            </div>
          </div>
          <div style={{ fontSize: "1rem" }}>
            <div>Last Updated</div>
            <div
              style={{
                fontSize: "1.2rem",
                color: "#09c4ff",
                fontWeight: "normal",
              }}
            >
              {lastUpdatedDate}
            </div>
            <div
              style={{
                fontSize: "1.2rem",
                color: "#09c4ff",
                fontWeight: "normal",
              }}
            >
              {lastUpdatedTime}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default LevelDevice;
