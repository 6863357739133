import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Col, FormControl, FormLabel, InputGroup, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import bg from "../../../../assets/img/login-background.png";
import logo from "../../../../assets/img/logo.svg";
import Swal from "sweetalert2";
import axios from "../../../../utils/axios";

const AdminLogin = ({ setIsAdminAuthenticated }) => {
  const [toggle, setToggle] = useState(false);
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post("login/admin", {
        username,
        password,
      });

      if (response?.data?.message === "login successfull") {
        localStorage.setItem("adminLogin", response.data.message);
        setIsAdminAuthenticated(true);
        navigate("/admin-panel");
      } else {
        Swal.fire({
          title: "Sorry!",
          text:
            response.data.message === "login is not successfull"
              ? "Please Check your password"
              : response.data.message,
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="login-screen">
      {/* <Loading /> */}
      <div className="login-body">
        <Row className="h-100 g-0">
          <Col lg="6" className="h-100">
            <div className="login-bg">
              <img src={bg} alt="" />
            </div>
          </Col>
          <Col lg="6" className="h-100">
            <div className="d-flex h-100 flex-column align-items-center justify-content-center">
              <img src={logo} className="logo logo-text" alt="logo" />

              <form onSubmit={handleLogin}>
                <div className="form-input-group mt-0">
                  <FormLabel>Username:</FormLabel>
                  <FormControl
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    placeholder="enter your username..."
                    type="text"
                  />
                </div>

                <div className="form-input-group">
                  <FormLabel>Password:</FormLabel>
                  <InputGroup>
                    <FormControl
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      placeholder="enter your password..."
                      type={!toggle ? "password" : "text"}
                    />
                    <InputGroup.Text
                      onClick={() => {
                        setToggle(!toggle);
                      }}
                    >
                      <FontAwesomeIcon icon={!toggle ? faEyeSlash : faEye} />
                    </InputGroup.Text>
                  </InputGroup>
                </div>

                <button type="submit" className="btn btn-submit">
                  LOGIN
                </button>
              </form>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default AdminLogin;
