import React, { useEffect, useState } from "react";
import DeviceCard from "../DeviceListing/DeviceCard";
import Swal from "sweetalert2";
import { FormControl, FormLabel, Modal } from "react-bootstrap";
import axios from "../../../../utils/axios";
import { useParams } from "react-router-dom";

const CustomerDetail = () => {
  const { id: customerId } = useParams();
  const [userDetail, setUserDetail] = useState([]);
  const [devices, setDevices] = useState([]);
  const [subscriptionEnd, setSubscriptionEnd] = useState("");

  const [deviceDetail, setDeviceDetail] = useState([]);
  const [show, setShow] = useState(false);
  const [deviceStatusMap, setDeviceStatusMap] = useState({});
  const [name, setName] = useState("");
  const [id, setId] = useState("");

  const handleDeviceStatusUpdate = (id, status) => {
    setDeviceStatusMap((prevStatusMap) => ({
      ...prevStatusMap,
      [id]: status,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await axios
        .post("change/device/details", {
          id: deviceDetail.id,
          alias_name: name,
        })
        .then((res) => {
          Swal.fire({
            title: "Success",
            text: "Details are successfully updated",
            icon: "success",
          });
          handleDetail();
        });

      await axios.post("change/deviceId", {
        deviceId: id,
        id: deviceDetail.id,
      });

      setShow(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDetail = async () => {
    await axios
      .post("view/customer/detail/device/list", {
        customerId: +customerId,
      })
      .then((response) => {
        setUserDetail(response.data.customerDetails);
        setDevices(response.data.devices);
        setSubscriptionEnd(
          new Date(response.data.subscriptionEnd).toLocaleDateString("en-US", {
            year: "numeric",
            month: "short",
            day: "numeric",
          })
        );
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    handleDetail();
  }, [customerId]);

  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await axios
          .post("unassign/customer", {
            deviceId: id,
          })
          .then((response) => response.json())
          .catch((error) => console.log(error));

        handleDetail();
      }
    });
  };

  const handleUpdate = async (id) => {
    if (id) {
      await axios
        .post("view/device/details", {
          deviceId: id,
        })
        .then((response) => {
          setDeviceDetail(response.data[0]);
          setName(response.data[0].alias_name);
          setId(response.data[0].deviceId);
        })
        .catch((error) => console.log(error));

      setShow(true);
    }
  };

  const handleReset = (e) => {
    e.preventDefault();
    setName(deviceDetail.alias_name);
    setId(deviceDetail.deviceId);
  };

  return (
    <div>
      {/* <Loading /> */}
      <div className="user-page-header">
        <h2 className="user-detail-name text-center">{userDetail.name}</h2>
        <p className="text-center user-detail-company">
          {userDetail.company_name}
        </p>

        <div className="user-contact-details d-flex align-items-center justify-content-center">
          <p className="detail-contact">
            {" "}
            <span>
              <svg
                width="43"
                height="43"
                viewBox="0 0 43 43"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M35.1689 35.4497H7.23038C5.76072 35.448 4.35174 34.8634 3.31253 33.8242C2.27332 32.785 1.68874 31.376 1.68701 29.9064V13.185C1.68874 11.7153 2.27332 10.3063 3.31253 9.26712C4.35174 8.22791 5.76072 7.64333 7.23038 7.6416H35.1689C36.6386 7.64333 38.0476 8.22791 39.0868 9.26712C40.126 10.3063 40.7106 11.7153 40.7123 13.185V29.9064C40.7106 31.376 40.126 32.785 39.0868 33.8242C38.0476 34.8634 36.6386 35.448 35.1689 35.4497ZM7.23038 9.59808C6.27908 9.59808 5.36674 9.97599 4.69407 10.6487C4.0214 11.3213 3.64349 12.2337 3.64349 13.185V29.9064C3.64349 30.8577 4.0214 31.77 4.69407 32.4427C5.36674 33.1153 6.27908 33.4932 7.23038 33.4932H35.1689C36.1202 33.4932 37.0326 33.1153 37.7052 32.4427C38.3779 31.77 38.7558 30.8577 38.7558 29.9064V13.185C38.7558 12.2337 38.3779 11.3213 37.7052 10.6487C37.0326 9.97599 36.1202 9.59808 35.1689 9.59808H7.23038Z"
                  fill="#222222"
                />
                <path
                  d="M21.1998 25.5109C19.993 25.5128 18.8185 25.1213 17.8542 24.3957L2.62623 12.8786C2.51864 12.8031 2.42737 12.7066 2.35787 12.5951C2.28838 12.4835 2.24208 12.3591 2.22174 12.2292C2.2014 12.0993 2.20745 11.9667 2.23951 11.8392C2.27157 11.7118 2.329 11.592 2.40836 11.4873C2.48772 11.3825 2.58738 11.2948 2.70139 11.2293C2.81541 11.1639 2.94144 11.1222 3.07195 11.1065C3.20247 11.0909 3.33479 11.1018 3.46102 11.1384C3.58725 11.1751 3.7048 11.2368 3.80664 11.3199L19.0346 22.8501C19.6599 23.3169 20.4194 23.5691 21.1998 23.5691C21.9801 23.5691 22.7396 23.3169 23.3649 22.8501L38.5929 11.3199C38.6947 11.2368 38.8123 11.1751 38.9385 11.1384C39.0648 11.1018 39.1971 11.0909 39.3276 11.1065C39.4581 11.1222 39.5841 11.1639 39.6981 11.2293C39.8122 11.2948 39.9118 11.3825 39.9912 11.4873C40.0705 11.592 40.128 11.7118 40.16 11.8392C40.1921 11.9667 40.1981 12.0993 40.1778 12.2292C40.1575 12.3591 40.1112 12.4835 40.0417 12.5951C39.9722 12.7066 39.8809 12.8031 39.7733 12.8786L24.5454 24.3957C23.5811 25.1213 22.4065 25.5128 21.1998 25.5109Z"
                  fill="#222222"
                />
                <path
                  d="M3.04999 32.7238C2.85159 32.7241 2.65778 32.6641 2.4943 32.5517C2.33081 32.4393 2.20537 32.2798 2.13462 32.0944C2.06387 31.9091 2.05116 31.7066 2.09817 31.5138C2.14518 31.3211 2.24969 31.1472 2.39783 31.0152L14.3976 20.3132C14.5913 20.1403 14.8458 20.0513 15.1051 20.066C15.3644 20.0807 15.6072 20.1978 15.7802 20.3915C15.9531 20.5852 16.0421 20.8397 16.0274 21.099C16.0127 21.3583 15.8956 21.6011 15.7019 21.7741L3.70215 32.476C3.52395 32.6382 3.2909 32.7267 3.04999 32.7238ZM39.3493 32.7238C39.1083 32.7267 38.8753 32.6382 38.6971 32.476L26.6973 21.7741C26.5036 21.6011 26.3865 21.3583 26.3719 21.099C26.3572 20.8397 26.4461 20.5852 26.6191 20.3915C26.792 20.1978 27.0349 20.0807 27.2942 20.066C27.5534 20.0513 27.8079 20.1403 28.0017 20.3132L39.9949 31.0152C40.143 31.1472 40.2475 31.3211 40.2946 31.5138C40.3416 31.7066 40.3289 31.9091 40.2581 32.0944C40.1874 32.2798 40.0619 32.4393 39.8984 32.5517C39.7349 32.6641 39.5411 32.7241 39.3427 32.7238H39.3493Z"
                  fill="#222222"
                />
              </svg>
            </span>{" "}
            {userDetail.email_id}
          </p>

          <p className="detail-contact">
            <span>
              <svg
                width="42"
                height="43"
                viewBox="0 0 42 43"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M35.5702 27.6635L32.7105 24.8038C31.858 23.9582 30.7074 23.4814 29.5067 23.4763C28.306 23.4712 27.1513 23.9381 26.2917 24.7765C25.833 25.2378 25.2279 25.5248 24.5805 25.5881C23.933 25.6515 23.2838 25.4872 22.7444 25.1235C20.6155 23.7027 18.7866 21.8774 17.3616 19.7512C17.0044 19.2058 16.846 18.5539 16.9133 17.9054C16.9805 17.2569 17.2692 16.6514 17.7308 16.1909C18.5615 15.3304 19.0225 14.179 19.0149 12.983C19.0074 11.787 18.532 10.6415 17.6905 9.79163L14.8308 6.93192C13.9746 6.0787 12.8152 5.59961 11.6065 5.59961C10.3978 5.59961 9.23832 6.0787 8.38216 6.93192L7.55935 7.75604C3.26979 12.0456 3.59086 21.0641 12.5157 29.9837C17.8972 35.3665 23.3137 37.6204 27.706 37.6204C28.9989 37.6635 30.2875 37.4488 31.4966 36.9888C32.7057 36.5289 33.8112 35.8329 34.7486 34.9414L35.5728 34.1173C36.4275 33.2608 36.9074 32.1 36.9069 30.8899C36.9064 29.6798 36.4256 28.5193 35.5702 27.6635ZM33.7321 32.2793L32.908 33.1034C29.5284 36.4831 22.0685 35.8656 14.3511 28.147C6.63385 20.4284 6.01511 12.9633 9.39476 9.58365L10.2124 8.76083C10.5805 8.39403 11.079 8.18808 11.5987 8.18808C12.1184 8.18808 12.6169 8.39403 12.985 8.76083L15.8447 11.6205C16.2061 11.9838 16.4113 12.4739 16.4164 12.9863C16.4215 13.4988 16.2261 13.9929 15.872 14.3633C14.9936 15.2472 14.4457 16.4061 14.3201 17.6459C14.1944 18.8857 14.4986 20.131 15.1818 21.1732C16.8008 23.5961 18.8828 25.675 21.308 27.2904C22.3471 27.9736 23.5892 28.2797 24.8267 28.1573C26.0643 28.035 27.2224 27.4918 28.1076 26.6184C28.4773 26.26 28.9728 26.0611 29.4876 26.0642C30.0025 26.0674 30.4955 26.2724 30.8607 26.6353L33.7204 29.495C33.9048 29.6766 34.0513 29.8929 34.1517 30.1314C34.2521 30.3699 34.3044 30.6259 34.3055 30.8847C34.3065 31.1435 34.2564 31.3999 34.1581 31.6393C34.0597 31.8786 33.9149 32.0961 33.7321 32.2793Z"
                  fill="black"
                />
              </svg>
            </span>{" "}
            {userDetail.mobile_no}{" "}
          </p>
        </div>
      </div>

      <div className="detail-body">
        {devices?.length === 0 ? (
          <h6 className="device-count text-center text-danger">
            {" "}
            No device was assigned to this user{" "}
          </h6>
        ) : (
          <>
            <h6 className="device-count">
              Assigned Devices (
              {devices?.length > 10 ? devices?.length : `0${devices?.length}`})
            </h6>

            {Array.isArray(devices) &&
              devices?.map((item) => (
                <div>
                  <DeviceCard
                    link={`/device-listing/device-detail/${item?.id}`}
                    onStatusUpdate={handleDeviceStatusUpdate}
                    deviceType={item.device_type}
                    deviceId={item.id}
                    name={item.alias_name}
                    company={item.company_name}
                    endDate={new Date(item.subscription_end).toLocaleDateString(
                      "en-US",
                      {
                        year: "numeric",
                        month: "short",
                        day: "numeric",
                      }
                    )}
                    onUpdate={() => handleUpdate(item.id)}
                    assignee={
                      item.customer_id !== null || item.dealer_id !== null
                        ? true
                        : false
                    }
                    onDelete={() => {
                      customerId && handleDelete(item.id);
                    }}
                    isAdmin
                  />
                </div>
              ))}
          </>
        )}
      </div>

      <Modal
        show={show}
        dialogClassName="modal-75w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <button className="close-icon" onClick={() => setShow(false)}>
          <svg
            width="31"
            height="31"
            viewBox="0 0 31 31"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18.4039 15.7032L30.3126 3.81334C30.6685 3.4574 30.8685 2.97463 30.8685 2.47125C30.8685 1.96787 30.6685 1.4851 30.3126 1.12916C29.9567 0.77321 29.4739 0.573242 28.9705 0.573242C28.4671 0.573242 27.9844 0.77321 27.6284 1.12916L15.7386 13.0379L3.84879 1.12916C3.49285 0.77321 3.01008 0.573243 2.5067 0.573243C2.00332 0.573243 1.52055 0.77321 1.16461 1.12916C0.808663 1.4851 0.608695 1.96787 0.608695 2.47125C0.608695 2.97463 0.808663 3.4574 1.16461 3.81334L13.0733 15.7032L1.16461 27.593C0.987436 27.7687 0.846811 27.9778 0.750844 28.2081C0.654878 28.4385 0.605469 28.6855 0.605469 28.9351C0.605469 29.1846 0.654878 29.4317 0.750844 29.662C0.846811 29.8924 0.987436 30.1014 1.16461 30.2772C1.34033 30.4543 1.5494 30.595 1.77975 30.6909C2.01009 30.7869 2.25716 30.8363 2.5067 30.8363C2.75624 30.8363 3.00331 30.7869 3.23366 30.6909C3.464 30.595 3.67307 30.4543 3.84879 30.2772L15.7386 18.3684L27.6284 30.2772C27.8041 30.4543 28.0132 30.595 28.2435 30.6909C28.4739 30.7869 28.721 30.8363 28.9705 30.8363C29.22 30.8363 29.4671 30.7869 29.6975 30.6909C29.9278 30.595 30.1369 30.4543 30.3126 30.2772C30.4898 30.1014 30.6304 29.8924 30.7264 29.662C30.8223 29.4317 30.8717 29.1846 30.8717 28.9351C30.8717 28.6855 30.8223 28.4385 30.7264 28.2081C30.6304 27.9778 30.4898 27.7687 30.3126 27.593L18.4039 15.7032Z"
              fill="black"
            />
          </svg>
        </button>
        <Modal.Body>
          <h4 className="modal-title">Add Device</h4>
          <form action="" className="modal-form">
            <div className="form-fields">
              <div>
                <FormLabel>Device Id</FormLabel>
                <FormControl
                  value={id}
                  onChange={(e) => setId(e.target.value)}
                  placeholder="Enter Device Id....."
                />
              </div>

              <div>
                <FormLabel>Device Name</FormLabel>
                <FormControl
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Enter Device Name....."
                />
              </div>
            </div>

            <div className="form-btn d-flex align-items-center mt-5">
              <button
                onClick={handleSubmit}
                type="submit"
                className="white-btn"
              >
                Save
              </button>
              <button className="white-btn ms-4" onClick={handleReset}>
                Reset
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default CustomerDetail;
