import React, { useState } from "react";
import ChartView from "./ChartView";
import TableView from "./TableView";

const Report = () => {
  const [activeTab, setActiveTab] = useState("");

  const handleTabCliCk = (tabName) => {
    setActiveTab(tabName);
  };
  return (
    <div className="report-screen">
      {/* <Loading /> */}
      <div className="tab-header">
        <button
          className={`btn btn-tab ${activeTab === "" ? "active" : ""}`}
          onClick={() => handleTabCliCk("")}
        >
          Chart View
        </button>
        <div className="seperator"></div>
        <button
          className={`btn btn-tab ${activeTab === "table" ? "active" : ""}`}
          onClick={() => handleTabCliCk("table")}
        >
          Table View
        </button>
      </div>

      <div className="tab-body">
        {activeTab === "" && (
          <ChartView
            reportFlowAPI="report/flow/devices/betweenDates/disjoint"
            reportLevelAPI="report/level/devices/betweenDates"
            viewDevicesAPI="view/devices"
          />
        )}
        {activeTab === "table" && (
          <TableView
            viewDeviceAPI="view/devices"
            flowReportDayAPI="flow/report/devices/betweenDates/group/day"
            levelReportDayAPI="level/report/devices/betweenDates/group/day"
            flowReportMonthAPI="flow/report/devices/betweenDates/group/month"
            levelReportMonthAPI="level/report/devices/betweenDates/group/month"
            flowReportHourAPI="flow/report/devices/betweenDates/group/hour"
            levelReportHourAPI="level/report/devices/betweenDates/group/hour"
            flowReportAPIwithoutFilter="report/flow/devices/betweenDates"
            levelReportAPIwithoutFilter="report/level/devices/betweenDates"
            workHourAPI="report/flow/workhour"
            flowBatteryReport="report/battery/flow"
            levelBatteryReport="report/battery/level"
          />
        )}
      </div>
    </div>
  );
};

export default Report;
