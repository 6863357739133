import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { FormControl, FormLabel, InputGroup } from "react-bootstrap";
import { useNavigate } from "react-router";
import axios from "../../../../utils/axios";

const DealerLogin = ({ setIsSetAuthenticated }) => {
  const [toggle, setToggle] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post("login/dealer", {
        email,
        password,
      });
      localStorage.setItem("accessToken", response.data.accessToken);
      localStorage.setItem("refreshToken", response.data.refreshToken);
      console.log(response.data);
      setIsSetAuthenticated(true);
      navigate("/dealer-panel");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <form onSubmit={handleLogin}>
      <div className="form-input-group mt-0">
        <FormLabel>Email:</FormLabel>
        <FormControl
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="enter your email..."
          type="email"
        />
      </div>

      <div className="form-input-group">
        <FormLabel>Password:</FormLabel>
        <InputGroup>
          <FormControl
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="enter your password..."
            type={!toggle ? "password" : "text"}
          />
          <InputGroup.Text
            onClick={() => {
              setToggle(!toggle);
            }}
          >
            <FontAwesomeIcon icon={!toggle ? faEyeSlash : faEye} />
          </InputGroup.Text>
        </InputGroup>
        <p className="text-end text-sm">Forgot Password?</p>
      </div>

      <button type="submit" className="btn btn-submit">
        LOGIN
      </button>
    </form>
  );
};

export default DealerLogin;
