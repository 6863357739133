import axios from "axios";

import toast from "react-hot-toast";

const getNewAccessToken = async (instance, refreshToken, accessToken) => {
  if (refreshToken !== "undefined" && refreshToken !== undefined) {
    try {
      const newTtoken = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/token`,
        {
          token: refreshToken,
        },
        {
          headers: {
            Authorization: "Bearer " + refreshToken,
            "Content-Type": "application/json",
          },
        }
      );
      return newTtoken;
    } catch (error) {
      toast.error("Session timed out, Please login again.");
      await instance.post("logout", { token: accessToken });
      localStorage.clear();
      window.location.href = "/";
      return false;
    }
  } else {
    localStorage.clear();
  }
};

const axiosInstance = () => {
  const defaultOptions = {
    baseURL: `${process.env.REACT_APP_BASE_URL}/`,
  };

  const instance = axios.create(defaultOptions);

  instance.interceptors.request.use((config) => {
    const token = localStorage.getItem("accessToken");
    config.headers.Authorization = token ? `Bearer ${token}` : "";
    config.headers.Accept = "application/json";
    config.headers["Content-Type"] = "application/json";
    return config;
  });

  instance.interceptors.response.use(
    (response) => response,
    async (error) => {
      const prevRequest = error?.config;
      if (error?.response?.status === 403 && !prevRequest?._retry) {
        prevRequest._retry = true;
        const refreshToken = localStorage.getItem("refreshToken");
        const accessToken = localStorage.getItem("accessToken");

        console.log("refreshToken", refreshToken);
        console.log("accessToken", accessToken);
        
        if (
          !refreshToken ||
          refreshToken === "undefined" ||
          refreshToken === undefined
        ) {
          localStorage.removeItem("accessToken");
          localStorage.removeItem("refreshToken");
          window.location.href = "/";
        } else {
          const newAccessToken = await getNewAccessToken(
            instance,
            refreshToken,
            accessToken
          );
          localStorage.setItem(
            "accessToken",
            newAccessToken?.data?.accessToken
          );
        }

        return instance(prevRequest);
      }
      return Promise.reject(error);
    }
  );

  return instance;
};

export default axiosInstance();
