import React, { useState, useEffect } from "react";
import { FormLabel, FormSelect } from "react-bootstrap";
import axios from "../../../../../utils/axios";

const AssignCustomer = (props) => {
  const [deviceList, setDeviceList] = useState([]);

  const getList = async () => {
    try {
      const viewDeviceList = await axios.post("view/device/list");
      setDeviceList(viewDeviceList?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getList();
  }, []);

  useEffect(() => {
    (async () => {
      if (props.customerId && props.device) {
        try {
          await axios.post("assign/customer", {
            deviceId: props.device,
            customerId: props.customerId,
          });
        } catch (error) {
          console.log(error);
        }
      }
    })();
  }, [props.customerId, props.device]);

  const filteredData = deviceList.filter((item) => item.customer_id === null);
  return (
    Array.isArray(filteredData) &&
    filteredData?.length !== 0 && (
      <div>
        <FormLabel>Add Device</FormLabel>
        {filteredData?.length > 0 && (
          <FormSelect value={props.value} onChange={props.onChange}>
            <option value="">Select Device</option>
            {filteredData.map((item) => (
              <option value={item.id}>{item.alias_name}</option>
            ))}
          </FormSelect>
        )}
      </div>
    )
  );
};

export default AssignCustomer;
