import { createContext, useContext, useEffect, useState } from "react";

const initialData = {
  timeUnit: {
    currentUnit: localStorage.getItem("timeUnit") || "hour",
    previousUnit: "hour",
  },
  measurementUnit: {
    currentUnit: localStorage.getItem("measurementUnit") || "cubicCentimeter",
    previousUnit: "cubicCentimeter",
  },
  setTimeUnit: () => {},
  setMeasurementUnit: () => {},
};

const FlowContext = createContext(initialData);

const FlowUnitContextProvider = ({ children }) => {
  const [measurementUnit, setMeasurementUnit] = useState(
    initialData.measurementUnit
  );
  const [timeUnit, setTimeUnit] = useState(initialData.timeUnit);

  useEffect(() => {
    localStorage.setItem("measurementUnit", measurementUnit?.currentUnit);
    localStorage.setItem("timeUnit", timeUnit?.currentUnit);
  }, [measurementUnit, timeUnit]);
  return (
    <FlowContext.Provider
      value={{ timeUnit, measurementUnit, setTimeUnit, setMeasurementUnit }}
    >
      {children}
    </FlowContext.Provider>
  );
};

function useFlowUnitContext() {
  const context = useContext(FlowContext);
  if (context === undefined) {
    throw new Error("Please provide Flow unit context Provider");
  }
  return context;
}
export { useFlowUnitContext, FlowUnitContextProvider };
