import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import axios from "../../../../utils/axios";
import TableFooter from "../../../Table/Footer";
import TableFilters from "../../../Table/TableFilters";
import ExportTableData from "../../../Table/ExportTableData";
import ReactVirtualizedTable from "./MaterialTable";
import {
  batteryReportColumns,
  flowReportNoIntervalColums,
  flowReportWithIntervalColums,
  levelColumns,
  levelNoIntervalColums,
  levelPerHourColums,
  levelPerMonthColums,
  pumpReportColums,
} from "./columns";
import toast from "react-hot-toast";

const TableView = ({
  viewDeviceAPI,
  flowReportDayAPI,
  levelReportDayAPI,
  flowReportMonthAPI,
  levelReportMonthAPI,
  flowReportHourAPI,
  levelReportHourAPI,
  workHourAPI,
  flowReportAPIwithoutFilter,
  levelReportAPIwithoutFilter,
  flowBatteryReport,
  levelBatteryReport,
}) => {
  const [deviceId, setDeviceId] = useState();
  const [chartData, setChartData] = useState([]);
  const [type, setType] = useState("");

  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);

  const [startTime, setStartTime] = useState(dayjs(yesterday));
  const [endTime, setEndTime] = useState(dayjs(today));

  // report type
  const [reportType, setReportType] = useState("Flow Report");
  const [interval, setInterval] = useState("");

  // device selection
  const [selectedDevice, setSelectedDevice] = useState("");
  const [options, setOptions] = useState([]);

  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    // Reorder the options array based on the selected value
    if (selectedDevice && options.includes(selectedDevice)) {
      setOptions([
        selectedDevice,
        ...options.filter((option) => option !== selectedDevice),
      ]);
    }
  }, [selectedDevice]);

  const handleDeviceSelect = (option, id, type) => {
    setSelectedDevice(option);
    setDeviceId(id);
    // setCurrentPage(0);
    setType(type);
  };

  const applyDateRange = () => {
    fetchData();
  };

  const getList = async () => {
    try {
      const getDeviceList = await axios.post(viewDeviceAPI);
      const flowDevices = getDeviceList?.data;
      setOptions(
        flowDevices.map((item) => {
          return {
            id: item.id,
            alias_name: item.alias_name,
            type: item.device_type,
          };
        })
      );
      setSelectedDevice(flowDevices[0].alias_name);
      setDeviceId(flowDevices[0].id);
      setType(flowDevices[0].device_type);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getList();
  }, []);

  const fetchData = async () => {
    toast.loading("Fetching data...please wait.");
    setChartData([]);
    const modifiedStartTime = startTime.format();
    const modifiedEndTime = endTime.format();
    const payload = {
      deviceId: [deviceId],
      start_date: modifiedStartTime,
      end_date: modifiedEndTime,
      limit: -1,
      page: -1,
    };
    if (reportType === "Flow Report") {
      if (interval === "Per day") {
        if (type === "flow") {
          try {
            const response = await axios.post(flowReportDayAPI, payload);
            setChartData(response?.data?.[0].data?.results);
            setTotalPages(response?.data?.[0]?.data?.totalRecords);
          } catch (error) {
            console.log(error);
          }
        } else {
          try {
            const response = await axios.post(levelReportDayAPI, payload);
            setChartData(response?.data?.[0].data?.results);
            setTotalPages(response?.data?.[0]?.data?.totalRecords);
          } catch (error) {
            console.log(error);
          }
        }
      } else if (interval === "Per month") {
        if (type === "flow") {
          try {
            const response = await axios.post(flowReportMonthAPI, payload);
            setChartData(response?.data?.[0].data?.results);
            setTotalPages(response?.data?.[0]?.data?.totalRecords);
          } catch (error) {
            console.log(error);
          }
        } else {
          try {
            const response = await axios.post(levelReportMonthAPI, payload);
            setChartData(response?.data?.[0].data?.results);
            setTotalPages(response?.data?.[0]?.data?.totalRecords);
          } catch (error) {
            console.log(error);
          }
        }
      } else if (interval === "Per hour") {
        if (type === "flow") {
          try {
            const response = await axios.post(flowReportHourAPI, payload);
            setChartData(response?.data?.[0].data?.results);
            setTotalPages(response?.data?.[0]?.data?.totalRecords);
          } catch (error) {
            console.log(error);
          }
        } else {
          try {
            const response = await axios.post(levelReportHourAPI, payload);
            setChartData(response?.data?.[0].data?.results);
            setTotalPages(response?.data?.[0]?.data?.totalRecords);
          } catch (error) {
            console.log(error);
          }
        }
      } else {
        if (type === "flow") {
          try {
            const response = await axios.post(
              flowReportAPIwithoutFilter,
              payload
            );
            setChartData(response?.data?.[0].data?.results);
            setTotalPages(response?.data?.[0]?.data?.totalRecords);
          } catch (error) {
            console.log(error);
          }
        } else {
          try {
            const response = await axios.post(
              levelReportAPIwithoutFilter,
              payload
            );
            setChartData(response?.data?.[0].data?.results);
            setTotalPages(response?.data?.[0]?.data?.totalRecords);
          } catch (error) {
            console.log(error);
          }
        }
      }
    } else if (reportType === "Battery Report") {
      if (type === "flow") {
        try {
          const response = await axios.post(flowBatteryReport, payload);

          setChartData(response?.data?.[0].data?.results);
          setTotalPages(response?.data?.[0]?.data?.totalRecords);
        } catch (error) {
          console.log(error);
        }
      } else {
        try {
          const response = await axios.post(levelBatteryReport, payload);
          setChartData(response?.data?.[0].data?.results);
          setTotalPages(response?.data?.[0]?.data?.totalRecords);
        } catch (error) {
          console.log(error);
        }
      }
    } else {
      try {
        const response = await axios.post(workHourAPI, payload);
        setChartData(response?.data?.[0].data?.results);
        setTotalPages(response?.data?.[0]?.data?.totalRecords);
      } catch (error) {
        console.log(error);
      }
    }
    toast.dismiss();
  };
  const handleExportClick = async ({ limit, page }) => {
    let result;
    const modifiedStartTime = startTime.format();
    const modifiedEndTime = endTime.format();
    const payload = {
      deviceId: [deviceId],
      start_date: modifiedStartTime,
      end_date: modifiedEndTime,
      limit,
      page,
    };
    if (reportType === "Flow Report") {
      if (interval === "Per day") {
        if (type === "flow") {
          try {
            result = await axios.post(flowReportDayAPI, payload);
          } catch (error) {
            console.log(error);
          }
        } else {
          try {
            result = await axios.post(levelReportDayAPI, payload);
          } catch (error) {
            console.log(error);
          }
        }
      } else if (interval === "Per month") {
        if (type === "flow") {
          try {
            result = await axios.post(flowReportMonthAPI, payload);
          } catch (error) {
            console.log(error);
          }
        } else {
          try {
            result = await axios.post(levelReportMonthAPI, payload);
          } catch (error) {
            console.log(error);
          }
        }
      } else if (interval === "Per hour") {
        if (type === "flow") {
          try {
            result = await axios.post(flowReportHourAPI, payload);
          } catch (error) {
            console.log(error);
          }
        } else {
          try {
            result = await axios.post(levelReportHourAPI, payload);
          } catch (error) {
            console.log(error);
          }
        }
      } else {
        if (type === "flow") {
          try {
            result = await axios.post(flowReportAPIwithoutFilter, payload);
          } catch (error) {
            console.log(error);
          }
        } else {
          try {
            result = await axios.post(levelReportAPIwithoutFilter, payload);
          } catch (error) {
            console.log(error);
          }
        }
      }
    } else if (reportType === "Battery Report") {
      if (type === "flow") {
        try {
          result = await axios.post(flowBatteryReport, payload);
        } catch (error) {
          console.log(error);
        }
      } else {
        try {
          result = await axios.post(levelBatteryReport, payload);
        } catch (error) {
          console.log(error);
        }
      }
    } else {
      try {
        result = await axios.post(workHourAPI, payload);
      } catch (error) {
        console.log(error);
      }
    }
    return result?.data?.[0].data?.results;
  };

  useEffect(() => {
    if (deviceId) {
      fetchData();
    }
  }, [deviceId, interval, reportType]);

  const handleChangeRowsPerPage = (event) => {
    setItemsPerPage(parseInt(event.target.value, 10));
    // setCurrentPage(0);
  };

  let selectedColumns;

  if (reportType === "Battery Report") {
    selectedColumns = batteryReportColumns;
  } else if (type === "flow") {
    if (reportType === "Flow Report") {
      selectedColumns =
        interval === ""
          ? flowReportNoIntervalColums
          : flowReportWithIntervalColums;
    } else {
      selectedColumns = pumpReportColums;
    }
  } else {
    selectedColumns = interval === "" ? levelNoIntervalColums : levelColumns;
  }

  return (
    <>
      <ExportTableData
        chartData={chartData}
        interval={interval}
        reportType={reportType}
        selectedDevice={selectedDevice}
        type={type}
        handleExportClick={handleExportClick}
      />
      <TableFilters
        deviceList={options}
        deviceType={type}
        endTime={endTime}
        setEndTime={setEndTime}
        handleDeviceSelect={handleDeviceSelect}
        handleOnSubmit={applyDateRange}
        selectedDevice={selectedDevice}
        setReportType={setReportType}
        setStartTime={setStartTime}
        startTime={startTime}
        setInterval={setInterval}
        setCurrentPage={setCurrentPage}
        reportType={reportType}
      />

      <ReactVirtualizedTable
        rows={chartData}
        reportType={reportType}
        interval={interval}
        columns={selectedColumns}
      />

      {/* <div
        className="table-content"
        id="report-table"
        style={{ height: "900px" }}
      >
        <table>
          <TableFooter
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            totalPages={totalPages}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            rowsPerPage={itemsPerPage}
          />
        </table>
      </div> */}
    </>
  );
};

export default TableView;
