import { MobileDateTimePicker } from "@mui/x-date-pickers";
import { useState } from "react";
import FilterDropdown from "../utilities/FilterDropdown";

const TableFilters = ({
  selectedDevice,
  deviceList,
  startTime,
  setStartTime,
  endTime,
  setEndTime,
  handleOnSubmit,
  handleDeviceSelect,
  setReportType,
  deviceType,
  setInterval,
  reportType,
  setCurrentPage,
}) => {
  const [toggle, setToggle] = useState(false);
  return (
    <div className="table-filter-header">
      <div
        className="date-range d-xl-flex align-items-end justify-content-between"
        style={{ gap: "10px" }}
      >
        <div className="d-grid">
          <label className="input-label">Device</label>
          <div className="dropdown">
            <button
              className="dropdown__button d-flex justify-content-between"
              onClick={() => setToggle(!toggle)}
              style={{ padding: "12px" }}
            >
              {selectedDevice}
              <span className={toggle && "rotate"}>
                <svg
                  width="33"
                  height="33"
                  viewBox="0 0 33 33"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.6304 21.0315L25.2136 13.4348C25.3394 13.3101 25.4392 13.1616 25.5074 12.9981C25.5755 12.8345 25.6106 12.6591 25.6106 12.4819C25.6106 12.3047 25.5755 12.1293 25.5074 11.9657C25.4392 11.8022 25.3394 11.6537 25.2136 11.529C24.9621 11.279 24.6219 11.1387 24.2674 11.1387C23.9128 11.1387 23.5726 11.279 23.3211 11.529L16.6103 18.1727L9.96662 11.529C9.71515 11.279 9.37497 11.1387 9.02039 11.1387C8.66581 11.1387 8.32564 11.279 8.07417 11.529C7.94735 11.6533 7.84646 11.8015 7.77734 11.9651C7.70822 12.1287 7.67226 12.3043 7.67152 12.4819C7.67226 12.6595 7.70822 12.8351 7.77734 12.9987C7.84646 13.1623 7.94735 13.3105 8.07417 13.4348L15.6574 21.0315C15.7831 21.1677 15.9356 21.2764 16.1054 21.3508C16.2752 21.4252 16.4585 21.4636 16.6439 21.4636C16.8292 21.4636 17.0126 21.4252 17.1824 21.3508C17.3521 21.2764 17.5047 21.1677 17.6304 21.0315Z"
                    fill="#09C4FF"
                  />
                </svg>
              </span>
            </button>
            <ul
              className={toggle ? "dropdown__menu show" : "dropdown__menu"}
              style={{ zIndex: 10 }}
            >
              {Array.isArray(deviceList) &&
                deviceList?.map((device) => (
                  <li
                    key={device?.id}
                    className={`dropdown__item ${
                      selectedDevice === device?.alias_name ? "active" : ""
                    }`}
                    onClick={() => {
                      handleDeviceSelect(
                        device?.alias_name,
                        device?.id,
                        device?.type
                      );
                      setCurrentPage(0);
                    }}
                  >
                    {device?.alias_name}
                  </li>
                ))}
            </ul>
          </div>
        </div>

        {deviceType === "flow" && (
          <div className="d-grid">
            <label className="input-label">Report Type</label>
            <FilterDropdown
              options={["Flow Report", "Pump Hour Report", "Battery Report"]}
              placeholder="Flow Report"
              onOptionSelect={(option) => {
                setCurrentPage(0);
                setReportType(option);
              }}
            />
          </div>
        )}
        {reportType === "Flow Report" && (
          <div className="d-grid">
            <label className="input-label">Interval</label>
            <FilterDropdown
              options={["Per hour", "Per day", "Per month"]}
              placeholder="Select Type"
              onOptionSelect={(option) => {
                setCurrentPage(0);
                setInterval(option);
              }}
            />
          </div>
        )}
        <div>
          <label className="input-label">Start date</label>
          <div className="date-input-group">
            <MobileDateTimePicker
              defaultValue={startTime}
              format="DD/MM/YYYY hh:mm A"
              slotProps={{
                textField: {
                  fullWidth: true,
                },
              }}
              value={startTime}
              onChange={setStartTime}
            />
          </div>
        </div>

        <div>
          <label className="input-label">End date</label>
          <div className="date-input-group">
            <MobileDateTimePicker
              defaultValue={startTime}
              format="DD/MM/YYYY hh:mm A"
              value={endTime}
              onChange={setEndTime}
              slotProps={{
                textField: {
                  fullWidth: true,
                },
              }}
            />
          </div>
        </div>

        <button
          onClick={() => handleOnSubmit()}
          className="white-btn"
          style={{ padding: "12px" }}
        >
          Submit
        </button>
      </div>
    </div>
  );
};
export default TableFilters;
