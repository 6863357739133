import { pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import pdfIcon from "../../assets/icons/Pdf.svg";
import excel from "../../assets/icons/Excel.svg";
import { formateDateTime } from "../../utils/formattedDateTime";
import ExportTableView from "../pages/User/Report/exports/ExportTableView";
import toast from "react-hot-toast";

const ExportTableData = ({
  reportType,
  type,
  interval,
  selectedDevice,
  handleExportClick,
  exportData,
}) => {
  function exportToExcel(chartData) {
    let chartType;
    console.log(chartData);
    if (reportType === "Flow Report") {
      if (type === "flow") {
        if (interval === "") {
          chartType = chartData.map((data) => ({
            dateTime: data.date_time,
            flowrate: data.flowrate,
            totalizer: data.totalizer,
            cumulativeTotalizer: data.change_totalizer,
          }));
        } else {
          chartType = chartData.map((data) => ({
            dateTime: data?.date_column,
            flowrate: data.avg_flowrate,
            totalizer: data.max_totalizer,
            cumulativeTotalizer: data.change_max_totalizer,
          }));
        }
      } else {
        if (interval === "") {
          chartType = chartData.map((data) => ({
            level: data.level,
            cumulativeTotalizer: data.level,
            dateTime: data.date_time,
          }));
        } else if (interval === "Per hour") {
          chartType = chartData.map((data) => ({
            level: data.avg_level,
            cumulativeTotalizer: data.max_level,
            dateTime: data.date,
            hour: data.hour,
          }));
        } else if (interval === "Per month") {
          chartType = chartData.map((data) => ({
            level: data.avg_level,
            cumulativeTotalizer: data.max_level,
            year: data.year,
            month: data.month,
          }));
        } else {
          chartType = chartData.map((data) => ({
            level: data.avg_level,
            cumulativeTotalizer: data.max_level,
            dateTime: `${new Date(data.date)
              .toLocaleDateString("en-GB")
              .slice(0, 10)} ${new Date(data.date).toLocaleTimeString("en-US", {
              hour12: false,
            })}`,
          }));
        }
      }
    } else if (reportType === "Battery Report") {
      chartType = chartData?.map((data) => ({
        startTime: data.start,
        endTime: data.end,
        battery: "ON",
      }));
    } else {
      chartType = chartData.map((data) => ({
        startTime: data.start_time,
        endTime: data.end_time,
        time: Math.round(
          (new Date(data.end_time) - new Date(data.start_time)) /
            (1000 * 60 * 60)
        ),
        cumulativeTime: Math.round(
          (new Date(data.end_time) - new Date(data.start_time)) /
            (1000 * 60 * 60)
        ),
      }));
    }

    const worksheet = XLSX.utils.json_to_sheet(chartType);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Chart Data");
    return workbook;
  }

  async function handleExcelExport() {
    toast.success("Data will be exported shortly.");
    const getData = await handleExportClick({ limit: -1, page: -1 });
    const workbook = exportToExcel(getData);
    XLSX.writeFile(
      workbook,
      `${selectedDevice}_${formateDateTime(new Date())}.xlsx`
    );
  }
  
  const handlePDFExport = async () => {
    toast.success("Data will be exported shortly.");

    const getData = await handleExportClick({ limit: 500, page: 1 });

    const blb = await pdf(
      <ExportTableView
        currentItems={getData}
        interval={interval}
        type={type}
        reportType={reportType}
        tableHead={
          reportType === "Flow Report"
            ? [
                "Index",
                "Date / Time",
                "Flow",
                "Totalizer",
                "Cumulative Totalizer",
              ]
            : reportType === "Battery Report"
            ? ["Index", "Start Date/Time", "End Date/Time", "Battery"]
            : ["Index", "Interval", "Time", "Cumulative Time"]
        }
      />
    ).toBlob();
    saveAs(blb, `${selectedDevice}_${formateDateTime(new Date())}`);
  };

  return (
    <div className="export-wrap d-flex justify-content-end mb-4">
      <div className="export-btn">
        Export
        <button onClick={() => handleExcelExport()}>
          <img src={excel} alt="excel" />
        </button>
        <button onClick={() => handlePDFExport()}>
          <img src={pdfIcon} alt="excel" />
        </button>
      </div>
    </div>
  );
};
export default ExportTableData;
