import React, { useEffect, useState } from "react";
import axios from "../../../../utils/axios";
import { Box } from "@mui/material";
import LevelDevice from "./Devices/LevelDevice";
import parseNumber from "../../../../utils/parseCalculate";

function Item(props) {
  const { sx, ...other } = props;
  return <Box {...other} />;
}

const TankContainer = (props) => {
  const [deviceDetail, setDeviceDetail] = useState({});
  const [deviceStatus, setDeviceStatus] = useState();
  const [levelValue, setLevelValue] = useState("--");
  const handleDeviceDetail = async (id) => {
    try {
      const response = await axios.post("view/level/lastdata", {
        deviceId: id,
      });
      const derivedResponse = response?.data;
      setDeviceDetail(derivedResponse);

      if (derivedResponse?.data !== undefined) {
        setLevelValue(parseNumber(derivedResponse?.data?.level));
      } else {
        setLevelValue("--");
      }
      if (derivedResponse?.data !== undefined) {
        const targetTime = new Date(derivedResponse?.data?.date_time);
        const currentTime = new Date();

        // Calculate the difference between the two times in milliseconds
        const timeDiff = Math.abs(currentTime - targetTime);

        // Check if the difference is less than 3 seconds (3000 milliseconds)
        if (timeDiff < 30000) {
          setDeviceStatus("active");
        } else {
          setDeviceStatus("inactive");
        }
      } else {
        setDeviceStatus("inactive");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await handleDeviceDetail(props.deviceId);
    };

    fetchData(); // Fetch data immediately when the component mounts

    const interval = setInterval(() => {
      fetchData(); // Fetch data and update status every 3 seconds
    }, 3000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <Item>
      <LevelDevice
        deviceStatus={deviceStatus}
        lastUpdatedDate={
          deviceDetail?.data?.date_time &&
          new Date(deviceDetail?.data?.date_time)
            .toLocaleDateString("en-GB")
            .slice(0, 10)
        }
        lastUpdatedTime={
          deviceDetail?.data?.date_time &&
          new Date(deviceDetail?.data?.date_time).toLocaleTimeString("en-US", {
            hour12: true,
          })
        }
        levelValue={`${levelValue} (${deviceDetail?.level_unit})`}
        title={props.name}
      />
    </Item>
  );
};

export default TankContainer;
