import React, { useState, useEffect } from "react";
import { MapContainer, TileLayer, Marker, Tooltip } from "react-leaflet";
import L from "leaflet";
import UserDeviceCard from "../../../utilities/UserDeviceCard";
import FilterDropdown from "../../../utilities/FilterDropdown";
import "leaflet/dist/leaflet.css";
import icon from "../../../../assets/icons/maps-and-flags.png";
import axios from "../../../../utils/axios";

const DeviceList = () => {
  const [activeTab, setActiveTab] = useState("");
  const [listDevices, setListDevices] = useState([]);
  //   const [locationData, setLocationData] = useState();
  const [sortOrder, setSortOrder] = useState("");
  const [deviceStatusMap, setDeviceStatusMap] = useState({});

  const getList = async () => {
    try {
      const getDeviceList = await axios.post("view/devices");
      if (activeTab === "flow") {
        setListDevices(
          getDeviceList?.data?.filter((item) => item.device_type === "flow")
        );
      } else if (activeTab === "level") {
        setListDevices(
          getDeviceList?.data?.filter((item) => item.device_type === "level")
        );
      } else {
        setListDevices(getDeviceList?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const locationData = [];

  useEffect(() => {
    getList();
  }, [activeTab, sortOrder]);

  listDevices.forEach((item) => {
    const lat = item.latitude;
    const long = item.longitude;
    const name = item.alias_name;
    locationData.push({ name, lat, long });
  });

  const handleTabCliCk = (tabName) => {
    setActiveTab(tabName);
  };

  const handleSortChange = (selectedOption) => {
    setSortOrder(selectedOption);
  };
  const sortedDevices = () => {
    let filteredDevices = listDevices;

    if (activeTab === "active") {
      filteredDevices = filteredDevices.filter(
        (device) => new Date(device.subscription_end) >= new Date()
      );
    } else if (activeTab === "inactive") {
      filteredDevices = filteredDevices.filter(
        (device) => new Date(device.subscription_end) < new Date()
      );
    }

    if (sortOrder === "Name A-Z") {
      return filteredDevices.sort((a, b) =>
        a.alias_name.localeCompare(b.alias_name)
      );
    } else if (sortOrder === "Name Z-A") {
      return filteredDevices.sort((a, b) =>
        b.alias_name.localeCompare(a.alias_name)
      );
    } else {
      return filteredDevices;
    }
  };

  const handleDeviceStatusUpdate = (id, status) => {
    setDeviceStatusMap((prevStatusMap) => ({
      ...prevStatusMap,
      [id]: status,
    }));
  };

  return (
    <div className="user-detail user-device-list-wrap">
      {/* <Loading /> */}
      <div className="listing-with-tab">
        {/* tab header */}
        <div className="tab-header">
          <button
            className={`btn btn-tab ${activeTab === "" ? "active" : ""}`}
            onClick={() => handleTabCliCk("")}
          >
            All
          </button>

          <div className="seperator"></div>

          <button
            className={`btn btn-tab ${activeTab === "active" ? "active" : ""}`}
            onClick={() => handleTabCliCk("active")}
          >
            Active Subscriptions
          </button>
          <button
            className={`btn btn-tab ${
              activeTab === "inactive" ? "active" : ""
            }`}
            onClick={() => handleTabCliCk("inactive")}
          >
            Inactive Subscriptions
          </button>

          <div className="seperator"></div>

          <button
            className={`btn btn-tab ${activeTab === "flow" ? "active" : ""}`}
            onClick={() => handleTabCliCk("flow")}
          >
            Flow
          </button>
          <button
            className={`btn btn-tab ${activeTab === "level" ? "active" : ""}`}
            onClick={() => handleTabCliCk("level")}
          >
            Level
          </button>
        </div>
        {/* tab header */}

        {/* page sorting and info */}
        <div className="device-head d-flex align-items-center justify-content-between">
          <h3 className="title">device list</h3>

          <div className="right-btn-wrap d-flex align-items-center justify-content-end">
            <FilterDropdown
              placeholder="Sort By"
              options={["Name A-Z", "Name Z-A"]}
              onOptionSelect={(option) => handleSortChange(option)}
              onChange={handleSortChange}
            />
          </div>
        </div>
        {/* page sorting and info */}

        {/* tab body */}
        <div className="tab-body">
          {sortedDevices().map((item) => (
            <div>
              <UserDeviceCard
                // {...deviceProps}
                type={item.device_type}
                id={item.id}
                className="user-device"
                name={item.alias_name}
                company={item.company_name}
                flow="100.98"
                totalFlow="2100.98"
                link={`/device-detail/${item?.id}`}
                reportLink="/report"
                onStatusUpdate={handleDeviceStatusUpdate}
                allData={item}
              />
            </div>
          ))}
        </div>
        {/* tab body */}
      </div>

      <div className="map-wrap">
        <div className="device-head">
          <h3 className="title">device map</h3>
        </div>

        <div className="map-box">
          <MapContainer
            center={[
              locationData[0]?.lat ? locationData[0]?.lat : 22.3080421,
              locationData[0]?.long ? locationData[0]?.long : 70.7748381,
            ]}
            zoom={8}
            scrollWheelZoom={false}
            style={{ height: "100vh", width: "100%" }}
          >
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            {locationData.map((marker) => (
              <Marker
                position={[
                  marker.lat ? marker.lat : 22.3080421,
                  marker.long ? marker.long : 70.7748381,
                ]}
                icon={L.icon({
                  iconUrl: icon,
                  iconSize: [40, 40],
                  iconAnchor: [12, 41],
                  popupAnchor: [1, -34],
                })}
              >
                <Tooltip>{marker.name}</Tooltip>
              </Marker>
            ))}
          </MapContainer>
        </div>
      </div>
    </div>
  );
};

export default DeviceList;
