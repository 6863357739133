import { BrowserRouter, Route, Routes } from "react-router-dom";
import Layout from "./components/pages/User/layout";
import AdminLayout from "./components/pages/Admin/layout";
import {
  AdminDeviceDetail,
  AdminLogin,
  AdminPanel,
  AllReports,
  CustomerDetail,
  CustomerListing,
  DealerCustomerListing,
  DealerDetail,
  DealerDeviceDetail,
  DealerDevices,
  DealerListing,
  DealerPanel,
  DealerReport,
  DealerSettings,
  DeviceDetail,
  DeviceList,
  DeviceListing,
  Login,
  Report,
  Settings,
  UserAdminPanel,
} from "./components";
import { useEffect, useState } from "react";
import DealerLayout from "./components/pages/Dealer/Layout";

function App() {
  const accessToken = localStorage.getItem("accessToken");

  const [isSetAuthenticated, setIsSetAuthenticated] = useState(!!accessToken);
  const [isAdminAuthenticated, setIsAdminAuthenticated] = useState(
    !!localStorage.getItem("adminLogin")
  );
  console.log("isAdminAuthenticated", isAdminAuthenticated);
  useEffect(() => {
    if (!accessToken) {
      setIsSetAuthenticated(false);
    }
  }, [accessToken]);

  return (
    <BrowserRouter>
      <Routes>
        {/* ======== user pages::start ======= */}
        {isSetAuthenticated ? (
          <>
            <Route
              path="/*"
              element={<Layout setIsSetAuthenticated={setIsSetAuthenticated} />}
            >
              <Route
                index
                element={
                  <UserAdminPanel
                    setIsSetAuthenticated={setIsSetAuthenticated}
                  />
                }
              />
              <Route path="report" element={<Report />} />
              <Route path="device-list" element={<DeviceList />} />
              <Route path="device-detail/:id" element={<DeviceDetail />} />
              <Route
                path="settings"
                element={
                  <Settings setIsSetAuthenticated={setIsSetAuthenticated} />
                }
              />
            </Route>

            <Route
              path="/*"
              element={
                <DealerLayout setIsSetAuthenticated={setIsSetAuthenticated} />
              }
            >
              <Route path="dealer-panel" element={<DealerPanel />} />
              <Route
                path="dealer-customer"
                element={<DealerCustomerListing />}
              />
              <Route
                path="dealer-customer/customer-detail/:id"
                element={<CustomerDetail />}
              />
              <Route path="dealer-Devices" element={<DealerDevices />} />

              <Route
                path="dealer-device-listing/device-detail/:id"
                element={<DealerDeviceDetail />}
              />

              <Route path="dealer-report" element={<DealerReport />} />

              <Route
                path="dealer-settings"
                element={
                  <DealerSettings
                    setIsSetAuthenticated={setIsSetAuthenticated}
                  />
                }
              />
            </Route>
          </>
        ) : (
          <>
            <Route
              path="*"
              element={<Login setIsSetAuthenticated={setIsSetAuthenticated} />}
            />
            <Route
              path="/login"
              element={<Login setIsSetAuthenticated={setIsSetAuthenticated} />}
            />
          </>
        )}
        {/* ======== user pages::end ======= */}

        {/* ======== admin pages :: start ========= */}
        {isAdminAuthenticated ? (
          <>
          <Route
              path="/admin-login"
              element={
                <AdminLogin setIsAdminAuthenticated={setIsAdminAuthenticated} />
              }
            />
          <Route path="/*" element={<AdminLayout />}>
            <Route path="admin-panel" element={<AdminPanel />} />
            
            <Route path="customer-listing" element={<CustomerListing />} />
            <Route
              path="customer-listing/customer-detail/:id"
              element={<CustomerDetail />}
            />
            <Route path="device-listing" element={<DeviceListing />} />
            <Route
              path="device-listing/device-detail/:id"
              element={<AdminDeviceDetail />}
            />
            <Route path="dealer-listing" element={<DealerListing />} />
            <Route
              path="dealer-listing/dealer-detail/:id"
              element={<DealerDetail />}
            />
            <Route path="admin-reports" element={<AllReports />} />
          </Route>
          </>
        ) : (
          <>
            <Route
              path="/admin-login"
              element={
                <AdminLogin setIsAdminAuthenticated={setIsAdminAuthenticated} />
              }
            />
            <Route
              path="*"
              element={
                <AdminLogin setIsAdminAuthenticated={setIsAdminAuthenticated} />
              }
            />
          </>
        )}

        {/* ======== admin pages :: end ========= */}

        {/* ======== dealer pages :: start ========= */}

        {/* ======== dealer pages :: end ========= */}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
