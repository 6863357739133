import React from "react";
import SidebarIcon from "./SidebarIcon";
import { useLocation } from "react-router-dom";

const Sidebar = () => {
  let location = useLocation();

  return (
    <div className="sidebar-wrap user">
      <SidebarIcon
        link="/"
        active={location.pathname === "/" && "active"}
        icon={
          <svg
            width="46"
            height="52"
            viewBox="0 0 46 52"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="home"
          >
            <path
              d="M9.86338 9.9798L22.7156 0.394043L35.2138 9.72467L44.9868 17.153V50.7199H26.2787V33.1623H22.7018L19.0587 33.1271V50.6951L0.284424 50.7199V17.1584L9.86338 9.9798Z"
              fill="#09C4FF"
            />
            <path
              d="M4.38912 46.5896H14.9219V29.0351H30.3699V46.5896H40.9026V19.2045L22.6459 5.51197L4.38912 19.2045V46.5896ZM0.176025 50.8027V17.098L22.6459 0.245605L45.1157 17.098V50.8027H26.1568V33.2482H19.1349V50.8027H0.176025Z"
              fill="#09C4FF"
            />
          </svg>
        }
      />

      <SidebarIcon
        link="/report"
        active={location.pathname === "/report" && "active"}
        icon={
          <svg
            width="61"
            height="61"
            viewBox="0 0 61 61"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.3287 11.6284C10.3287 8.31471 13.015 5.62842 16.3287 5.62842H34.0099C35.6876 5.62842 37.2887 6.33083 38.4248 7.56529L43.2016 12.7556L48.3886 17.5223C49.625 18.6585 50.3287 20.2609 50.3287 21.9401V49.6284C50.3287 52.9421 47.6424 55.6284 44.3287 55.6284H16.3287C13.015 55.6284 10.3287 52.9421 10.3287 49.6284V11.6284Z"
              fill="white"
              fillOpacity="0"
            />
            <path
              d="M18.668 47.2888V44.8271L20.6114 42.8836V47.2888H18.668ZM24.0125 47.2888V39.6445L25.9559 37.7011V47.2888H24.0125ZM29.357 47.2888V37.7011L31.3005 39.6769V47.2888H29.357ZM34.7015 47.2888V39.6769L36.645 37.7335V47.2888H34.7015ZM40.046 47.2888V34.462L41.9895 32.5185V47.2888H40.046ZM18.668 39.6445V36.8913L27.7375 27.8866L32.92 33.0692L41.9895 23.9673V26.7205L32.92 35.8224L27.7375 30.6398L18.668 39.6445Z"
              fill="#09C4FF"
            />
            <path
              d="M14.0787 55.6284C13.0787 55.6284 12.2037 55.2534 11.4537 54.5034C10.7037 53.7534 10.3287 52.8784 10.3287 51.8784V9.37842C10.3287 8.37842 10.7037 7.50342 11.4537 6.75342C12.2037 6.00342 13.0787 5.62842 14.0787 5.62842H36.6412L50.3287 19.3159V51.8784C50.3287 52.8784 49.9537 53.7534 49.2037 54.5034C48.4537 55.2534 47.5787 55.6284 46.5787 55.6284H14.0787ZM34.7662 21.0034V9.37842H14.0787V51.8784H46.5787V21.0034H34.7662Z"
              fill="#09C4FF"
            />
          </svg>
        }
      />

      <SidebarIcon
        link="/device-list"
        active={location.pathname === "/device-list" && "active"}
        icon={
          <svg
            width="71"
            height="71"
            viewBox="0 0 71 71"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M27.3306 42.2137V31.9598H24.4083V27.667H46.2143V31.9484H43.3438V42.1627H58.662V58.9618H11.9954V42.2137H27.3306Z"
              fill="white"
              fillOpacity="0"
            />
            <path
              d="M33.1412 25.4201V13.7534H21.4745V9.37842H49.1829V13.7534H37.5162V25.4201H33.1412ZM11.9954 58.9618V42.1909H16.3704H27.3079V31.9826H24.3912V27.6076H46.2662V31.9826H43.3495V42.1909H54.287H58.662V58.9618H54.287H16.3704H11.9954ZM16.3704 54.5868H54.287V46.5659H38.9745V31.9826H31.6829V46.5659H16.3704V54.5868Z"
              fill="#09C4FF"
            />
          </svg>
        }
      />

      <SidebarIcon
        icon={
          <svg
            width="71"
            height="71"
            viewBox="0 0 71 71"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M41.4537 61.8784L21.4746 41.1701V36.3576H30.9537C33.7246 36.3576 36.1551 35.4583 38.2454 33.6597C40.3357 31.8611 41.551 29.3576 41.8912 26.1493H17.8287V21.7743H41.5267C40.8947 19.4409 39.5944 17.5208 37.6256 16.0138C35.6569 14.5069 33.4329 13.7534 30.9537 13.7534H17.8287V9.37842H52.8287V13.7534H41.5996C42.7176 14.7256 43.6655 15.9652 44.4433 17.4722C45.2211 18.9791 45.7801 20.4131 46.1204 21.7743H52.8287V26.1493H46.2662C45.8773 30.5729 44.2367 34.1093 41.3444 36.7586C38.452 39.4079 34.9885 40.7326 30.9537 40.7326H27.1621L47.5058 61.8784H41.4537Z"
              fill="#09C4FF"
            />
          </svg>
        }
      />
    </div>
  );
};

export default Sidebar;
