export const flowReportNoIntervalColums = [
  {
    lable: "Date / Time",
    dataKey: "date_time",
    isInterval: false,
    secondKey: "",
    timeDifference: false,
    minWidth: 170,
    id: "1st",
    isDate: true,
  },
  {
    lable: "Flow",
    dataKey: "flowrate",
    isInterval: false,
    secondKey: "",
    timeDifference: false,
    minWidth: 100,
    id: "2st",
    isDate: false,
  },
  {
    lable: "Totalizer",
    dataKey: "totalizer",
    isInterval: false,
    secondKey: "",
    timeDifference: false,
    minWidth: 100,
    id: "3st",
    isDate: false,
  },
  {
    lable: "Change in Totalizer",
    dataKey: "change_totalizer",
    isInterval: false,
    secondKey: "",
    timeDifference: false,
    minWidth: 100,
    id: "4st",
    isDate: false,
  },
];

export const flowReportWithIntervalColums = [
  {
    lable: "Date / Time",
    isInterval: true,
    dataKey: "date_column",
    timeDifference: false,
    minWidth: 170,
    id: "5st",
    isDate: true,
  },
  {
    lable: "Avg. Flow",
    isInterval: false,
    dataKey: "avg_flowrate",
    intervalText: "",
    secondKey: "",
    timeDifference: false,
    minWidth: 100,
    id: "6st",
    isDate: false,
  },
  {
    lable: "Max Totalizer",
    isInterval: false,
    dataKey: "max_totalizer",
    intervalText: "",
    secondKey: "",
    timeDifference: false,
    minWidth: 100,
    id: "7st",
    isDate: false,
  },
  {
    lable: "Change in Max Totalizer",
    isInterval: false,
    dataKey: "change_max_totalizer",
    intervalText: "",
    secondKey: "",
    timeDifference: false,
    minWidth: 100,
    id: "8st",
    isDate: false,
  },
];

export const pumpReportColums = [
  {
    lable: "Date - Time",
    isInterval: true,
    dataKey: "datetime",
    intervalText: "To",
    secondKey: "end_time",
    timeDifference: false,
    minWidth: 170,
    id: "9st",
    isDate: false,
  },
  {
    lable: "Time (H:M:S)",
    isInterval: false,
    dataKey: "interval",
    intervalText: "",
    secondKey: "end_time",
    timeDifference: true,
    minWidth: 100,
    id: "10st",
    isDate: false,
  },
  {
    lable: "Cumulative Time (H:M:S)",
    isInterval: false,
    dataKey: "timediff",
    intervalText: "",
    secondKey: "end_time",
    timeDifference: true,
    minWidth: 100,
    id: "11st",
    isDate: false,
  },
];

export const levelNoIntervalColums = [
  {
    lable: "Date / Time",
    isInterval: false,
    dataKey: "date_time",
    intervalText: "",
    secondKey: "",
    timeDifference: false,
    minWidth: 170,
    id: "12st",
    isDate: true,
  },
  {
    lable: "Level",
    isInterval: false,
    dataKey: "level",
    intervalText: "",
    secondKey: "",
    timeDifference: false,
    minWidth: 100,
    id: "13st",
    isDate: false,
  },
  {
    lable: "Change in Level",
    isInterval: false,
    dataKey: "change_level",
    intervalText: "",
    secondKey: "",
    timeDifference: false,
    minWidth: 100,
    id: "14st",
    isDate: false,
  },
];

export const levelColumns = [
  {
    lable: "Date / Time",
    isInterval: true,
    dataKey: "date_column",
    intervalText: "|",
    secondKey: "hour",
    timeDifference: false,
    minWidth: 170,
    id: "15st",
    isDate: false,
  },
  {
    lable: "Max Level",
    isInterval: false,
    dataKey: "max_level",
    intervalText: "",
    secondKey: "",
    timeDifference: false,
    minWidth: 100,
    id: "16st",
    isDate: false,
  },
  {
    lable: "Change in Max Level",
    isInterval: false,
    dataKey: "change_max_level",
    intervalText: "",
    secondKey: "",
    timeDifference: false,
    minWidth: 100,
    id: "17st",
    isDate: false,
  },
];

export const batteryReportColumns = [
  {
    lable: "Date - Time",
    isInterval: true,
    dataKey: "datetime",
    intervalText: "To",
    secondKey: "end_time",
    timeDifference: false,
    minWidth: 170,
    id: "9st",
    isDate: false,
  },
  {
    lable: "Time (H:M:S)",
    isInterval: false,
    dataKey: "interval",
    intervalText: "",
    secondKey: "end_time",
    timeDifference: true,
    minWidth: 100,
    id: "10st",
    isDate: false,
  },
  {
    lable: "Cumulative Time (H:M:S)",
    isInterval: false,
    dataKey: "timediff",
    intervalText: "",
    secondKey: "end_time",
    timeDifference: true,
    minWidth: 100,
    id: "11st",
    isDate: false,
  },
];
