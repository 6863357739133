import React, { useState } from "react";
import { Col, FormControl, FormLabel, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "../../../../utils/axios";

const DealerSettings = ({ setIsSetAuthenticated }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [pass, setPass] = useState();
  const [confirmPass, setConfirmPass] = useState();
  const [company, setCompany] = useState("");

  const navigate = useNavigate();

  const handleEditProfile = async (e) => {
    e.preventDefault();
    try {
      if (pass !== "") {
        if (pass === confirmPass) {
          if (name !== "" && email !== "" && mobile !== "" && company !== "") {
            const response = await axios.post("change/details/dealer", {
              password: pass,
              name,
              email_id: email,
              mobile_no: mobile,
              company_name: company,
            });
            localStorage.removeItem("accessToken", response.data.accessToken);
            setIsSetAuthenticated(false);
            navigate("/login");
          } else {
            Swal.fire({
              title: "Try again!",
              text: "All fields are mandatory",
              icon: "error",
              confirmButtonText: "OK",
            });
          }
        } else {
          Swal.fire({
            title: "Try again!",
            text: "Password and confirm password must be same",
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      } else {
        const response = await axios.post("change/details/dealer", {
          name,
          email_id: email,
          mobile_no: mobile,
          company_name: company,
        });
        localStorage.removeItem("accessToken", response.data.accessToken);
        setIsSetAuthenticated(false);
        navigate("/login");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleReset = () => {
    setName("");
    setEmail("");
    setMobile("");
    setPass();
    setConfirmPass();
    setCompany("");
  };

  return (
    <div>
      <h3 className="text-center">Edit Profile</h3>
      <form onSubmit={handleEditProfile}>
        <Row className="g-4">
          <Col lg="4" md="6">
            <FormLabel>
              Name<span className="text-danger">*</span>:
            </FormLabel>
            <FormControl
              required
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Enter Name"
            />
          </Col>
          <Col lg="4" md="6">
            <FormLabel>
              Email ID<span className="text-danger">*</span>:
            </FormLabel>
            <FormControl
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter Email Id"
              type="email"
            />
          </Col>
          <Col lg="4" md="6">
            <FormLabel>
              Mobile No<span className="text-danger">*</span>:
            </FormLabel>
            <FormControl
              required
              value={mobile}
              onChange={(e) => setMobile(e.target.value)}
              placeholder="Enter Mobile No"
              type="number"
            />
          </Col>
          <Col lg="4" md="6">
            <FormLabel>Password:</FormLabel>
            <FormControl
              value={pass}
              onChange={(e) => setPass(e.target.value)}
              placeholder="Enter New Password"
              type="password"
            />
          </Col>
          <Col lg="4" md="6">
            <FormLabel>Confirm Password:</FormLabel>
            <FormControl
              value={confirmPass}
              onChange={(e) => setConfirmPass(e.target.value)}
              placeholder="Enter Confirm Password"
              type="password"
            />
          </Col>
          <Col lg="4" md="6">
            <FormLabel>
              Company Name<span className="text-danger">*</span>:
            </FormLabel>
            <FormControl
              required
              value={company}
              onChange={(e) => setCompany(e.target.value)}
              placeholder="Enter Your Company Name"
            />
          </Col>
          <Col md="6" className="d-flex justify-content-end">
            <button type="submit" className="white-btn">
              Save
            </button>
          </Col>
          <Col md="6" className="d-flex justify-content-start">
            <button className="white-btn" onClick={handleReset}>
              Reset
            </button>
          </Col>
        </Row>
      </form>
    </div>
  );
};

export default DealerSettings;
